import { postMySofRequest } from '../../config/apiClientMySOF';

const globalSlice = (set, get) => ({
  locale: 'en',
  setLocale: (value) => set(() => ({ locale: value })),
  userProfile: null,
  setUserProfile: (userProfileValue) =>
    set(() => ({ userProfile: userProfileValue })),
  showOverlay: false,
  setShowOverlay: (data) => set(() => ({ showOverlay: data })),
  employeeData: [],
  fetchEmployeeData: async () => {
    get().setShowOverlay(true);
    try {
      const response = await postMySofRequest('users/filter', {});
      if (response) {
        const {
          data: { users },
        } = response;
        const transformedData = users.map(
          ({ id, first_name, last_name, email }) => ({
            id: id,
            name: `${first_name} ${last_name}`,
            email: email,
          })
        );
        set(() => ({
          employeeData: transformedData,
          showOverlay: false,
        }));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
});

export default globalSlice;
