import { useLocation, useParams, Navigate, Outlet } from 'react-router-dom';

import useStore from '../store/store';

export default function LocaleCheck() {
  const location = useLocation();
  const { locale } = useParams();
  const localePresent = locale && ['en', 'fr'].includes(locale);
  const { locale: stateLocal } = useStore((state) => state);

  return localePresent ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/${stateLocal || 'en'}${location.pathname}`}
      state={{ from: location }}
      replace
    />
  );
}
