import {
  Typography,
  Image,
  Spacer,
  TextButton,
} from '@telus-uds/components-web';
import { useNavigate } from 'react-router-dom';

import LockImage from '../../assets/images/lock.png';

import useStore from '../../store/store';
import { ROLES } from '../../utils/constants';
import { removeLocalStorage } from '../../utils/utils';

export default function Error() {
  const navigate = useNavigate();
  const { userProfile } = useStore((state) => state);
  const { role_id: roleId } = userProfile;
  const { Admin, Manager, TeamMember } = ROLES;
  const isUserOfTimeTrackingRole = [Admin, Manager, TeamMember].includes(
    roleId
  );

  const onLogout = () => {
    removeLocalStorage('accessToken');
    navigate('/login');
  };

  return (
    <>
      <Spacer space={10} />
      <div className="flex flex-direction-vertical align-center justify-center gap-1">
        <Image src={LockImage} alt="Image of a Lock" width={150} />
        <Typography block variant={{ size: 'h1' }}>
          401 - Unauthorized
        </Typography>
        <Typography block variant={{ size: 'large' }}>
          {isUserOfTimeTrackingRole
            ? 'You are not authorized to access this page'
            : 'You are not authorized to access this tool'}
        </Typography>
        <div>
          {isUserOfTimeTrackingRole ? (
            <TextButton onPress={() => navigate('/')}>
              Go to home page
            </TextButton>
          ) : (
            <TextButton onPress={onLogout}>Logout</TextButton>
          )}
        </div>
      </div>
    </>
  );
}
