import axios from 'axios';
import { getLocalStorage } from '../utils/utils';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_API;

const config = () => ({
  headers: {
    platform: getLocalStorage('userPlatform'),
    Authorization: `Bearer ${getLocalStorage('accessToken')}`,
    'Access-Control-Allow-Origin': '*',
  },
});

export function getRequest(URL, options) {
  return axiosClient
    .get(`/${URL}`, { ...config(), ...(options && options) })
    .then((response) => response);
}

export function postRequest(URL, payload, options) {
  return axiosClient
    .post(`/${URL}`, payload, { ...config(), ...(options && options) })
    .then((response) => response);
}

export function putRequest(URL, payload) {
  return axiosClient
    .put(`/${URL}`, payload, config())
    .then((response) => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`/${URL}`, config()).then((response) => response);
}
