import { useState } from 'react';

import { Box, Button, Spacer, TextInput } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import { postRequest } from '../../../../../config/apiClient';
import useStore from '../../../../../store/store';

export default function AddTask() {
  const { t } = useTranslation(['adminControl', 'common']);
  const { newTaskName, setNewTaskName, fetchTasks, setTaskMessage } = useStore(
    (state) => state
  );
  const [addTaskError, setAddTaskError] = useState(false);

  const addTask = async () => {
    if (!newTaskName.trim()) {
      setAddTaskError(true);
      return;
    }

    try {
      const result = await postRequest('task', { name: newTaskName });

      if (result) {
        setTaskMessage({
          active: true,
          type: 'success',
          message: 'Activity added successfully',
        });
        setNewTaskName('');
        fetchTasks();
      }
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  return (
    <Box variant={{ background: 'light' }} space={4}>
      <TextInput
        label={t('labelTaskName')}
        value={newTaskName}
        onChange={(name) => {
          setNewTaskName(name);
          setAddTaskError(false);
        }}
        validation={addTaskError ? 'error' : ''}
        feedback={addTaskError ? 'Activity name cannot be blank.' : ''}
      />
      <Spacer space={3} />
      <Button onPress={addTask}>{t('common:labelAdd')}</Button>
    </Box>
  );
}
