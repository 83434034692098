import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { ChevronRight } from '@telus-uds/palette-allium/build/rn/icons';
import { Icon, Typography } from '@telus-uds/components-web';

export default function Breadcrumbs({ breadcrumbsItems }) {
  const getBreadcrumbsItems = () => {
    return breadcrumbsItems.map(({ path, breadcrumbName }) => {
      if (!path)
        return (
          <Typography block key={path}>
            {breadcrumbName}
          </Typography>
        );

      return (
        <Fragment key={path}>
          <Link to={`${path}`}>
            {breadcrumbName}
          </Link>
          <Icon
            icon={ChevronRight}
            variant={{ size: 'micro', color: 'subtle' }}
          />
        </Fragment>
      );
    });
  };

  return <div className="breadcrumbs-main">{getBreadcrumbsItems()}</div>;
}
Breadcrumbs.propTypes = {
  breadcrumbsItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      breadcrumbName: PropTypes.string,
    })
  ).isRequired,
};
