import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { FIRST_DAY_OF_WEEK } from '../../utils/constants';

export default function DateRangePicker({ label, range, setRange }) {
  return (
    <DateRange
      editableDateInputs={false}
      onChange={(item) => setRange([item.selection])}
      moveRangeOnFirstSelection={false}
      ranges={range}
      weekStartsOn={FIRST_DAY_OF_WEEK}
      rangeColors={['#4b286d']}
    />
  );
}
