import useStore from '../../../../../store/store';
import { getTotalEmployeeHoursPerProjectsFormattedData } from '../util';

import Chart from './Chart';

export default function TotalEmployeeHoursPerProjects() {
  const { reportingData, filtersData: { projectList } } = useStore();

  if (reportingData.length === 0) return null;

  const { categories, seriesData } =
    getTotalEmployeeHoursPerProjectsFormattedData(reportingData, projectList);

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'column',
      height: '600px',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Sum of total weekly hours',
      },
      stackLabels: {
        enabled: true,
      },
    },
    tooltip: {
      formatter: function () {
        return `Employee: <b>${this.x}</b></br>Project: <b>${this.series.name}</b></br>Project Hours: <b>${this.y}</b></br>Total Hours spent: <b>${this.point.stackTotal}</b>`;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y === 0 ? '' : this.y; // Display nothing if value is 0
          },
        },
      },
    },
    series: seriesData,
    responsive: {
      rules: [
        {
          chartOptions: {
            legend: {
              enabled: false,
            },
          },
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  };

  return (
    <Chart
      heading="Total Hours Worked by Project"
      options={options}
      downloadCsvFor="TOTAL_EMPLOYEE_HOURS_PER_PROJECT"
    />
  );
}
