import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    backend: {
      loadPath: '/locale/i18n/{{ns}}/{{lng}}.json',
    },
    debug: false,
    ns: ['timeEntry', 'adminControl', 'common', 'header', 'homePage'],
    interpolation: {
      escapeValue: false,
      formatSeprator: ',',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
