import { Spacer } from '@telus-uds/components-web';

import Breadcrumbs from '../../components/Breadcrumbs';
import Timesheet from './components/Timesheet';
import { BREADCRUMBS_LIST } from '../../utils/constants';

export default function AddTime() {
  return (
    <>
      <Breadcrumbs breadcrumbsItems={BREADCRUMBS_LIST.addTime} />
      <Spacer space={6} />
      <Timesheet />
    </>
  );
}
