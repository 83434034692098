export const BREADCRUMBS_LIST = {
  adminControl: [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: 'Admin Control',
    },
  ],
  addTime: [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: 'Add Time',
    },
  ],
  dashboardReporting: [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: 'Dashboard and Reporting',
    },
  ],
};

export const ROLES = {
  Admin: 12,
  Manager: 13,
  TeamMember: 14,
};

export const FIRST_DAY_OF_WEEK = 1; // 1 for Monday, 2 for Tuesday...7 for Sunday

export const helpLink = 'https://docs.google.com/document/d/14_2XPLIBvp_L49rdVoECfrlG3mZ0mdVV1B0QDScjYQk/preview'; // Link to the Help document
