import PropType from 'prop-types';
import { Pagination as TelusPagination } from '@telus-uds/components-web';

export default function Pagination({
  total,
  currentPage,
  ordersPerPage,
  setPage,
}) {
  const totalPages = Math.ceil(total / ordersPerPage);

  if (totalPages <= 1) return null;

  const pages = Array(totalPages).fill('');

  return (
    <TelusPagination sideButtonVariant={{ compact: true }}>
      {pages.map((_, index) => (
        <TelusPagination.PageButton
          key={`pagination-button-${index}`}
          onPress={(e) => setPage(index + 1)}
          isActive={currentPage === index + 1}
        />
      ))}
    </TelusPagination>
  );
}

Pagination.defaultProps = {
  ordersPerPage: 10,
  setPage: () => {},
};

Pagination.propType = {
  total: PropType.number.isRequired,
  currentPage: PropType.number.isRequired,
  ordersPerPage: PropType.number,
  setPage: PropType.function,
};
