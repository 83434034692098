import useStore from '../../../../../store/store';
import { getTotalHoursByTaskPerProjectFormattedData } from '../util';

import Chart from './Chart';

export default function TotalHoursByTaskPerProject() {
  const { reportingData } = useStore();

  if (reportingData.length === 0) return null;

  const { categories, seriesData } =
    getTotalHoursByTaskPerProjectFormattedData(reportingData);

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'column',
      height: 1000
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      labels: {
        formatter: function () {
          const splitArray = this.value.split('-');
          return `<b>${splitArray[0]}</b><br><b>${splitArray[1]}</b>`;
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Total hours',
      },
      stackLabels: {
        enabled: true,
      },
    },
    tooltip: {
      formatter: function () {
        const employeeName = this.x.split('-')[0];
        const projectName = this.x.split('-')[1];
        return `Employee: <b>${employeeName}</b></br>Project: <b>${projectName}</b></br>Activity: <b>${this.series.name}</b></br>Activity Hours: <b>${this.y}</b></br>Total Hours on Project: <b>${this.point.stackTotal}</b>`;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y === 0 ? '' : this.y;
          },
        },
      },
      series: {
        pointPadding: 0.07,
        groupPadding: 0.07
      }
    },
    series: seriesData,
    responsive: {
      rules: [
        {
          chartOptions: {
            legend: {
              enabled: false,
            },
          },
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  };

  return (
    <Chart
      heading="Total Hours Spent by Activity per Project"
      options={options}
      downloadCsvFor="TOTAL_HOURS_BY_TASK_PER_PROJECTS"
    />
  );
}
