import { CSVLink } from 'react-csv';
import { Button, TextButton, Responsive } from '@telus-uds/components-web';

import useStore from '../../../../../store/store';
import {
  getTotalEmployeeHoursAllProjectsCsvData,
  getTotalEmployeeHoursPerProjectsCsvData,
  getTotalEmployeeHoursPerTaskCsvData,
  getTotalHoursByProjectCsvData,
  getTotalHoursByTaskCsvData,
  getTotalHoursByTaskPerProjectCsvData,
} from '../util';

const CSV_NAME_MAPPING = {
  TOTAL_EMPLOYEE_HOURS_ALL_PROJECT: 'total-hours-by-resource',
  TOTAL_EMPLOYEE_HOURS_PER_PROJECT: 'total-hours-by-resource-per-project',
  TOTAL_EMPLOYEE_HOURS_PER_TASK: 'total-hours-by-resource-per-activity',
  TOTAL_HOURS_PROJECTS: 'total-hours-for-projects',
  TOTAL_HOURS_TASK: 'total-hours-for-activities',
  TOTAL_HOURS_BY_TASK_PER_PROJECTS: 'total-hours-by-task-per-project',
};

const getCSVData = (reportingData, downloadCsvFor) => {
  let result;
  if (downloadCsvFor === 'TOTAL_EMPLOYEE_HOURS_ALL_PROJECT')
    result = getTotalEmployeeHoursAllProjectsCsvData(reportingData);
  else if (downloadCsvFor === 'TOTAL_EMPLOYEE_HOURS_PER_PROJECT')
    result = getTotalEmployeeHoursPerProjectsCsvData(reportingData);
  else if (downloadCsvFor === 'TOTAL_EMPLOYEE_HOURS_PER_TASK')
    result = getTotalEmployeeHoursPerTaskCsvData(reportingData);
  else if (downloadCsvFor === 'TOTAL_HOURS_PROJECTS')
    result = getTotalHoursByProjectCsvData(reportingData);
  else if (downloadCsvFor === 'TOTAL_HOURS_TASK')
    result = getTotalHoursByTaskCsvData(reportingData);
  else if (downloadCsvFor === 'TOTAL_HOURS_BY_TASK_PER_PROJECTS')
    result = getTotalHoursByTaskPerProjectCsvData(reportingData);
  return result;
};

export default function DownloadCsv({ downloadCsvFor }) {
  const { reportingData } = useStore((state) => state);
  if (!reportingData || reportingData.length === 0) return null;
  const csvData = getCSVData(reportingData, downloadCsvFor);

  return (
    <>
      {csvData && (
        <CSVLink
          data={csvData}
          filename={`${CSV_NAME_MAPPING[downloadCsvFor]}.csv`}
        >
          <Responsive min="xs" max="md">
            <TextButton>Download CSV</TextButton>
          </Responsive>
          <Responsive min="lg" max="xl">
            <Button>Download CSV</Button>
          </Responsive>
        </CSVLink>
      )}
    </>
  );
}
