import { useNavigate } from 'react-router-dom';
import { ChevronRight } from '@telus-uds/palette-allium/build/rn/icons';
import {
  Spacer,
  Typography,
  FlexGrid,
  Box,
  Icon,
  TextButton,
} from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

export default function CardItem({ title, icon, url }) {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();

  return (
    <Box right={{ xs: 0, md: 2 }} bottom={{ xs: 2, md: 0 }}>
      <Box variant={{ background: 'light' }} space={5}>
        <FlexGrid>
          <FlexGrid.Row>
            <FlexGrid.Col xs={2} sm={2} lg={2} xl={2}>
              <Icon icon={icon} variant={{ size: 'large', color: 'brand' }} />
            </FlexGrid.Col>
            <FlexGrid.Col xs={10} sm={10} lg={10} xl={10}>
              <Typography
                block
                variant={{ size: 'medium', bold: true, colour: 'brand' }}
              >
                {title}
              </Typography>
            </FlexGrid.Col>
          </FlexGrid.Row>
        </FlexGrid>
        <Spacer space={4} />
        <TextButton onPress={() => navigate(`/${url}`)}>
          <FlexGrid gutter={false}>
            <FlexGrid.Row>
              <FlexGrid.Col>{t('accessLabel')}</FlexGrid.Col>
              <FlexGrid.Col>
                <Icon
                  icon={ChevronRight}
                  variant={{ size: 'medium', color: 'success' }}
                />
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid>
        </TextButton>
      </Box>
    </Box>
  );
}
