import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  CaretUp,
  CaretDown,
  Profile,
  LinkExternal,
  Support,
} from '@telus-uds/palette-allium/build/web/icons';
import {
  Icon,
  Link as LinkTelus,
  TextButton,
  Typography,
} from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import { removeLocalStorage } from '../../../utils/utils';
import useStore from '../../../store/store';
import { helpLink } from '../../../utils/constants';

export default function ProfileBoxMobile() {
  const { t } = useTranslation(['header']);
  const navigate = useNavigate();
  const { locale, setLocale, userProfile, setUserProfile } = useStore(
    (state) => state
  );

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [localeBoxOpen, setLocaleBoxOpen] = useState(false);

  const switchLocale = () => {
    const newLocale = locale === 'fr' ? 'en' : 'fr';
    const currentPathArray = [...window.location.pathname.split('/')];
    currentPathArray[1] = newLocale;
    const newPath = currentPathArray.join('/');
    setLocale(newLocale);
    navigate(`${newPath}`);
  };

  const onLogout = () => {
    removeLocalStorage('accessToken');
    setUserProfile(null);
    navigate(`/login`);
  };

  return (
    <>
      <button
        type="button"
        className={`hamburger-menu-main ${sidebarVisible ? 'open' : ''}`}
        onClick={() => setSidebarVisible((previousValue) => !previousValue)}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      {sidebarVisible && (
        <div className="mobile-sidebar">
          <aside>
            <div className="flex align-center gap-0-5 mobile-sidebar__username">
              <Icon icon={Profile} variant={{ size: 'large' }} />
              <Typography block variant={{ bold: true, size: 'h2' }}>
                {userProfile?.first_name} {userProfile?.last_name}
              </Typography>
            </div>
            <nav className="mobile-sidebar__navbar">
              <ul className="mobile-sidebar__navbar__list">
                <li className="mobile-sidebar__navbar__list__item">
                  <LinkTelus
                    href={helpLink}
                    hrefAttrs={{ target: '_blank' }}
                    variant={{ size: 'large' }}
                    tokens={{ color: '#676e73' }}
                  >
                    {t('labelHelp')}
                  </LinkTelus>
                </li>
                <li className="mobile-sidebar__navbar__list__item">
                  <div className="flex align-center gap-0-5">
                    <TextButton
                      onPress={() =>
                        setLocaleBoxOpen((currentState) => !currentState)
                      }
                      variant={{ alternative: true, size: 'large' }}
                      tokens={{ color: '#676e73' }}
                    >
                      {locale === 'en' ? 'EN' : 'FR'}
                    </TextButton>
                    <Icon
                      icon={localeBoxOpen ? CaretUp : CaretDown}
                      variant={{ size: 'small' }}
                    />
                  </div>
                  {localeBoxOpen && (
                    <div className="locale-box">
                      <ul className="flex flex-direction-vertical gap-1">
                        <li>
                          <p className="locale-selector-box__row">
                            {t('labelSelectLanguage')}:
                          </p>
                        </li>
                        <li>
                          <button
                            className={`locale-selector-box__row link ${
                              locale === 'en' ? 'active' : ''
                            }`}
                            onClick={switchLocale}
                          >
                            {t('labelEnglish')}
                          </button>
                        </li>
                        <li>
                          <button
                            className={`locale-selector-box__row link ${
                              locale === 'fr' ? 'active' : ''
                            }`}
                            onClick={switchLocale}
                          >
                            {t('labelFrench')}
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                <li className="mobile-sidebar__navbar__list__item">
                  <div className="flex align-center gap-0-5">
                    <TextButton
                      onPress={onLogout}
                      variant={{ alternative: true, size: 'large' }}
                      tokens={{ color: '#676e73' }}
                    >
                      {t('labelLogout')}
                    </TextButton>
                    <Icon icon={LinkExternal} variant={{ size: 'medium' }} />
                  </div>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
      )}
    </>
  );
}
