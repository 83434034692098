import { useState, useEffect } from 'react';
import useStore from '../../../../../store/store';
import { getTotalEmployeeHoursPerTaskFormattedData } from '../util';

import Chart from './Chart';

export default function TotalEmployeeHoursPerTask() {
  const { reportingData, filtersData: { taskList} } = useStore();
  const [chartType, setChartType] = useState('bar');

  const updateChartType = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const type = width < 600 ? 'column' : 'bar';
    setChartType(type);
  };

  if (reportingData.length === 0) return null;

  const { categories, seriesData } = getTotalEmployeeHoursPerTaskFormattedData(
    reportingData,
    taskList
  );

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: chartType,
      height: 500
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Sum of total weekly hours',
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      enabled: true,
      reversed: true,
    },
    tooltip: {
      formatter: function () {
        return `Employee: <b>${this.x}</b></br>Activity: <b>${this.series.name}</b></br>Activity Hours: <b>${this.y}</b></br>Total Hours spent: <b>${this.point.stackTotal}</b>`;
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        pointPadding: 0.07, 
        groupPadding: 0.07,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y === 0 ? '' : this.y;
          },
        },
      },
    },
    series: seriesData,
    responsive: {
      rules: [
        {
          chartOptions: {
            legend: {
              enabled: false,
            },
          },
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
  };

  useEffect(() => {
    updateChartType();

    window.addEventListener('resize', updateChartType);

    return () => {
      window.removeEventListener('resize', updateChartType);
    };
  }, []);

  return (
    <Chart
      heading="Total Hours Worked by Activity"
      options={options}
      downloadCsvFor="TOTAL_EMPLOYEE_HOURS_PER_TASK"
    />
  );
}
