import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  CaretUp,
  CaretDown,
  Profile,
  LinkExternal,
  Support,
} from '@telus-uds/palette-allium/build/web/icons';
import {
  Icon,
  Link as LinkTelus,
  TextButton,
  Typography,
} from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import { removeLocalStorage } from '../../../utils/utils';
import useStore from '../../../store/store';
import { helpLink } from '../../../utils/constants';

export default function ProfileBox() {
  const { t } = useTranslation(['header']);
  const navigate = useNavigate();
  const { locale, setLocale, userProfile, setUserProfile } = useStore(
    (state) => state
  );

  const [localBoxOpen, setLocaleBoxOpen] = useState(false);

  const switchLocale = () => {
    const newLocale = locale === 'fr' ? 'en' : 'fr';
    const currentPathArray = [...window.location.pathname.split('/')];
    currentPathArray[1] = newLocale;
    const newPath = currentPathArray.join('/');
    setLocale(newLocale);
    navigate(`${newPath}`);
  };

  const onLogout = () => {
    removeLocalStorage('accessToken');
    setUserProfile(null);
    navigate(`/login`);
  };

  useEffect(() => {
    const handleClick = () => {
      setLocaleBoxOpen(false);
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="flex gap-1-5 align-center ">
      <div className="flex align-center gap-0-5">
        <Icon icon={Profile} variant={{ size: 'medium' }} />
        <Typography block variant={{ bold: true, size: 'large' }}>
          {userProfile?.first_name} {userProfile?.last_name}
        </Typography>
      </div>
      <div className="flex align-center gap-0-5">
        <Icon icon={Support} variant={{ size: 'micro' }} />
        <LinkTelus
          href={helpLink}
          hrefAttrs={{ target: '_blank' }}
          variant={{ alternative: true }}
          tokens={{ color: '#676e73' }}
        >
          {t('labelHelp')}
        </LinkTelus>
      </div>
      <div className="flex align-center gap-0-5 position-relative">
        <TextButton
          onPress={() => setLocaleBoxOpen((currentState) => !currentState)}
          variant={{ alternative: true, size: 'medium' }}
          tokens={{ color: '#676e73' }}
        >
          {locale === 'en' ? 'EN' : 'FR'}
        </TextButton>
        <Icon
          icon={localBoxOpen ? CaretUp : CaretDown}
          variant={{ size: 'micro' }}
        />
        {localBoxOpen && (
          <div className="locale-selector-box">
            <div className="flex flex-direction-vertical gap-0-5">
              <p className="locale-selector-box__row">
                {t('labelSelectLanguage')}:
              </p>
              <button
                className={`locale-selector-box__row link ${
                  locale === 'en' ? 'active' : ''
                }`}
                onClick={switchLocale}
              >
                {t('labelEnglish')}
              </button>
              <button
                className={`locale-selector-box__row link ${
                  locale === 'fr' ? 'active' : ''
                }`}
                onClick={switchLocale}
              >
                {t('labelFrench')}
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="flex align-center gap-0-5">
        <TextButton
          onPress={onLogout}
          variant={{ alternative: true, size: 'medium' }}
          tokens={{ color: '#676e73' }}
        >
          {t('labelLogout')}
        </TextButton>
        <Icon icon={LinkExternal} variant={{ size: 'small' }} />
      </div>
    </div>
  );
}
