import { Modal as TelusModal } from '@telus-uds/components-web';

export default function ConfirmationModal({ data }) {
  const { isOpen, heading, bodyText, onClose, onConfirm } = data;

  return (
    <TelusModal
      isOpen={isOpen}
      onClose={() => onClose(false)}
      onCancel={() => onClose(false)}
      heading={heading}
      bodyText={bodyText}
      confirmButtonText="Confirm"
      onConfirm={onConfirm}
      cancelButtonText="Cancel"
    />
  );
}
