export default function CustomCheckbox({ id, label, isChecked, onCheck }) {
  const formattedId = label && label.replace(/\s+/g, '-').toLowerCase() + '-' + id;

  return (
    <div className="flex-container">
      <div className="custom-checkbox">
        <input
          type="checkbox"
          id={formattedId}
          checked={isChecked}
          onChange={() => onCheck(id)}
        />
        <label htmlFor={formattedId}></label>
      </div>
      <span>{label}</span>
    </div>
  );
}
