import useStore from '../../../../../store/store';
import { getTotalEmployeeHoursAllProjectsFormattedData } from '../util';

import Chart from './Chart';

export default function TotalEmployeeHoursAllProjects() {
  const { reportingData } = useStore();

  if (reportingData.length === 0) return null;

  const { categories, seriesData } =
    getTotalEmployeeHoursAllProjectsFormattedData(reportingData);

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      title: {
        text: '',
      },
    },
    yAxis: {
      title: {
        text: 'Sum of total weekly hours',
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return `Employee: <b>${this.x}</b><br/>Total Hours Worked: <b>${this.y}</b>`;
      },
    },
    series: [
      {
        name: 'Total hours worked',
        colorByPoint: true,
        data: seriesData,
        dataLabels: {
          enabled: true,
          format: '{y}',
          style: {
            color: '#000',
            fontSize: '12px',
          },
        },
      },
    ],
  };

  return (
    <Chart
      heading="Total Hours Worked by Resource"
      options={options}
      downloadCsvFor="TOTAL_EMPLOYEE_HOURS_ALL_PROJECT"
    />
  );
}
