import { Link } from 'react-router-dom';
import { Image, Responsive } from '@telus-uds/components-web';

import useStore from '../../store/store';

import ProfileBox from './components/ProfileBox';
import ProfileBoxMobile from './components/ProfileBoxMobile';

export default function Header() {
  const { userProfile } = useStore((state) => state);

  return (
    <header className="header">
      <div className="header__inner container">
        <div className="flex justify-space-between">
          <div className="header__inner__nav-bar__left-section">
            <Link to={`/`}>
              <Image
                src="https://images.ctfassets.net/fikanzmkdlqn/24Npa1YcWK80UQw5IZQunH/1ea5fdb2d1ee84b212ce778e713ab9f4/TELUS_Logo.svg"
                alt="TELUS Logo"
                width={145}
              />
            </Link>
          </div>
          {userProfile && (
            <>
              <Responsive min="md" max="xl">
                <ProfileBox />
              </Responsive>
              <Responsive min="xs" max="sm">
                <ProfileBoxMobile />
              </Responsive>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
