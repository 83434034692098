import { useState, useEffect } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import Layout from '../components/Layout';
import { getRequest } from '../config/apiClient';
import { removeLocalStorage } from '../utils/utils';
import useStore from '../store/store';

export default function ProtectedRoutes({ allowedRoles }) {
  const location = useLocation();
  const { setUserProfile, locale } = useStore((state) => state);
  const [isValidSession, setIsValidSession] = useState(false);
  const [isUserUnauthorized, setIsUserUnauthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSessionValidity = async () => {
      try {
        const response = await getRequest('auth/session');
        const {
          data: { data },
        } = response;
        if (data !== null) {
          const { role_id } = data;
          if (!allowedRoles.includes(role_id)) {
            setIsUserUnauthorized(true);
          }
          setUserProfile(data);
          setIsValidSession(true);
        } else {
          removeLocalStorage('accessToken');
        }
      } catch (error) {
        removeLocalStorage('accessToken');
        setIsValidSession(false);
      } finally {
        setLoading(false);
      }
    };
    checkSessionValidity();
  }, []);

  if (loading) return null;

  return (
    <>
      {!loading && isValidSession && !isUserUnauthorized ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : isUserUnauthorized ? (
        <Navigate to="/error" state={{ from: location }} replace />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
}
