import { useEffect } from 'react';

import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Icon, Typography, ButtonLink } from '@telus-uds/components-web';
import { HeadMale, Key } from '@telus-uds/palette-allium/build/rn/icons';

import logo from '../../assets/svg/Telus_logo.svg';
import { getLocalStorage, setLocalStorage } from '../../utils/utils';

export default function Login() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectedFrom = location?.state?.from?.pathname;
  const token = params.get('token');
  const accessToken = getLocalStorage('accessToken');

  useEffect(() => {
    /** for Fresh login process add token to the local storage */
    if (token && !accessToken) {
      setLocalStorage('accessToken', token);
    }
    /** If accessToken is available redirect then to home where the session will be checked */
    if (accessToken || token) {
      navigate(`${redirectedFrom || '/'}`);
    }
  }, []);

  return (
    <div className="login-container">
      <div className="login-container__inner">
        <img
          className="login-container__inner__logo"
          src={logo}
          alt="Telus Logo"
        />
        <div className="login-container__inner__signin-box">
          <div className="login-container__inner__signin-box__header-block">
            <Icon icon={HeadMale} variant={{ size: 'large', color: 'brand' }} />
            <Typography block variant={{ size: 'h3' }}>
              Log In
            </Typography>
          </div>
          <div className="login-container__inner__signin-box__button-block">
            <ButtonLink
              icon={Key}
              href={`${process.env.REACT_APP_API}/auth/login`}
            >
              Login via TELUS SSO
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
}
