import { getRequest } from '../../config/apiClient';

export const projectSlice = (set, get) => ({
  newProjectName: '',
  setNewProjectName: (name) => set(() => ({ newProjectName: name })),
  loadingProjects: false,
  setLoadingProjects: (value) => set(() => ({ loadingProjects: value })),
  projectMessage: { active: false, type: '', message: '' },
  setProjectMessage: (value) => set(() => ({ projectMessage: { ...value } })),
  currentPageNumberProject: 1,
  setCurrentPageNumberProject: (value) => {
    set(() => ({ currentPageNumberProject: value }));
    get().fetchProjects();
  },
  totalProjects: 0,
  setTotalProjects: (value) => set(() => ({ totalProjects: value })),
  projectList: [],
  setProjectList: (list) => set(() => ({ projectList: [...list] })),
  fetchProjects: async (pageSize = 10) => {
    set({ loadingProjects: true });
    const currentPage = get().currentPageNumberProject;

    try {
      const response = await getRequest(
        `project?page=${currentPage}&pageSize=${pageSize}&sort=name&dir=ASC`
      );
      const projects = response?.data?.data?.items;
      const total = response?.data?.data?.totalItems;

      set({ projectList: projects || [], totalProjects: total });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      set({ loadingProjects: false });
    }
  },
});

export const taskSlice = (set, get) => ({
  newTaskName: '',
  setNewTaskName: (name) => set(() => ({ newTaskName: name })),
  loadingTasks: false,
  setLoadingTasks: (value) => set(() => ({ loadingTasks: value })),
  taskMessage: { active: false, type: '', message: '' },
  setTaskMessage: (value) => set(() => ({ taskMessage: { ...value } })),
  currentPageNumberTask: 1,
  setCurrentPageNumberTask: (value) => {
    set(() => ({ currentPageNumberTask: value }));
    get().fetchTasks();
  },
  totalTasks: 0,
  setTotalTasks: (value) => set(() => ({ totalTasks: value })),
  taskList: [],
  setTaskList: (list) => set(() => ({ taskList: [...list] })),
  fetchTasks: async (pageSize = 10) => {
    set({ loadingTasks: true });
    const currentPage = get().currentPageNumberTask;

    try {
      const response = await getRequest(
        `task?page=${currentPage}&pageSize=${pageSize}&sort=name&dir=ASC`
      );
      const tasks = response?.data?.data?.items;
      const total = response?.data?.data?.totalItems;

      set({ taskList: tasks || [], totalTasks: total });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      set({ loadingTasks: false });
    }
  },
});
