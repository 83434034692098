import { useEffect } from 'react';

import { Spacer, Responsive, Spinner } from '@telus-uds/components-web';

import Breadcrumbs from '../../components/Breadcrumbs';
import Filters from './components/Filters';
import Reports from './components/Reports';
import DownloadRawData from './components/DownloadRawData';

import { BREADCRUMBS_LIST } from '../../utils/constants';
import useStore from '../../store/store';

export default function DashboardAndReporting() {
  const {
    fetchReportingData,
    showLoadingReportingData
  } = useStore((state) => state);

  useEffect(() => {
    fetchReportingData().then();
  }, []);

  return (
    <>
      <Breadcrumbs breadcrumbsItems={BREADCRUMBS_LIST.dashboardReporting} />
      <Spacer space={8} />
      {showLoadingReportingData ? (
        <Spinner label="Loading data..." show />
      ) : (
        <>
          <DownloadRawData />
          <Spacer space={4} />
          <div className="dashboard-reporting-main">
            <Responsive min="lg" max="xl">
              <Filters />
            </Responsive>
            <div className="reports">
              <Reports />
            </div>
          </div>
        </>
      )}
    </>
  );
}

