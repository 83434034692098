import {
  Spinner,
  Spacer,
  Table,
  FlexGrid,
  Responsive,
} from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../../../../../store/store';

import Pagination from '../../../../../components/Pagination';
import TaskListRow from './TaskListRow';

export default function TaskList() {
  const { t } = useTranslation(['adminControl']);
  const {
    loadingTasks,
    taskList,
    currentPageNumberTask,
    setCurrentPageNumberTask,
    totalTasks,
  } = useStore((state) => state);

  return (
    <Spinner label="Fetching tasks..." show={loadingTasks}>
      <Table>
        <Responsive min="xs" max="sm">
          <Table.Header>
            <Table.Cell>{t('labelTaskName')}</Table.Cell>
            <Table.Cell>{t('labelAction')}</Table.Cell>
          </Table.Header>
        </Responsive>
        <Responsive min="md" max="xl">
          <Table.Header>
            <Table.Cell>{t('labelTaskId')}</Table.Cell>
            <Table.Cell>{t('labelTaskName')}</Table.Cell>
            <Table.Cell>{t('labelAction')}</Table.Cell>
          </Table.Header>
        </Responsive>
        <Table.Body>
          {taskList.map((taskData) => (
            <TaskListRow key={taskData?.id} taskData={taskData} />
          ))}
        </Table.Body>
      </Table>
      <Spacer space={5} />
      <FlexGrid>
        <FlexGrid.Row>
          <FlexGrid.Col>
            <Pagination
              total={totalTasks}
              currentPage={currentPageNumberTask}
              setPage={setCurrentPageNumberTask}
            />
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </Spinner>
  );
}
