import { Spacer, Typography } from '@telus-uds/components-web';

import { useTranslation } from 'react-i18next';
import CardList from './components/CardList';

export default function Home() {
  const { t } = useTranslation(['homePage']);

  return (
    <>
      <Typography block variant={{ size: 'h2' }}>
        {t('homePageHeading')}
      </Typography>
      <Spacer space={6} />
      <CardList />
    </>
  );
}
