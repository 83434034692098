import { addDays, startOfWeek, format } from 'date-fns';
import {
  ChevronLeft,
  ChevronRight,
} from '@telus-uds/palette-allium/build/web/icons';
import { DatePicker, IconButton, Typography } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../../../../../../store/store';
import { FIRST_DAY_OF_WEEK } from '../../../../../../utils/constants';

export default function TopSection() {
  const { t } = useTranslation(['timeEntry']);
  const {
    userProfile,
    weekStartDate,
    setWeekStartDate,
    datePickerValue,
    setDatePickerValue,
  } = useStore((state) => state);
  if (!weekStartDate) return null;

  const formattedStartDate = format(weekStartDate, 'MMM d, yyyy');
  const formattedEndDate = format(addDays(weekStartDate, 6), 'MMM d, yyyy');

  const navigateToWeek = (weekOffset) => {
    const newWeek = addDays(weekStartDate, weekOffset * 7);
    setWeekStartDate(startOfWeek(newWeek, { weekStartsOn: FIRST_DAY_OF_WEEK }));
  };

  return (
    <div className="time-entry-top-bar">
      <div className="time-entry-top-bar__left-section">
        <div className="time-entry-top-bar__left-section__username">
          <div className="flex gap-0-5 align-center">
            <Typography block variant={{ bold: true }}>
              {t('labelTimesheetFor')}
            </Typography>
            <Typography block variant={{ size: 'eyebrow' }}>
              {userProfile?.first_name} {userProfile?.last_name}
            </Typography>
          </div>
        </div>
        <div className="time-entry-top-bar__left-section__entry-period flex gap-0-5 align-center">
          <Typography block variant={{ bold: true }}>
            {t('labelDateEntryPeriod')}
          </Typography>
          <div className="flex gap-0-5 align-center">
            <IconButton
              icon={ChevronLeft}
              variant={{ raised: true }}
              onPress={() => {
                navigateToWeek(-1);
              }}
            />
            <Typography block variant={{ size: 'eyebrow' }}>
              {formattedStartDate} - {formattedEndDate}
            </Typography>
            <IconButton
              icon={ChevronRight}
              variant={{ raised: true }}
              onPress={() => {
                navigateToWeek(1);
              }}
            />
          </div>
        </div>
      </div>
      <div className="time-entry-top-bar__right-section">
        <div className="time-entry-top-bar__right-section__date-picker">
          <DatePicker
            id="DatePicker"
            label={t('labelGoToSpecificDate')}
            date={datePickerValue}
            onDateChange={setDatePickerValue}
            isDayDisabled={() => false}
          />
        </div>
      </div>
    </div>
  );
}
