import { Notification as TelusNotification } from '@telus-uds/components-web';
import { useEffect } from 'react';

export default function Notification({ data, action }) {
  const { type, message } = data;
  const messageType = !['success', 'error', 'warning'].includes(type)
    ? 'success'
    : type;

  useEffect(() => {
    const messageTimer = setTimeout(() => {
      action({ ...data, active: false });
    }, 6000);

    return () => {
      clearTimeout(messageTimer);
    };
  }, []);

  return (
    <TelusNotification variant={{ style: messageType }}>
      {message}
    </TelusNotification>
  );
}
