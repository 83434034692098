import {
  Settings,
  Time,
  ChartsLine,
} from '@telus-uds/palette-allium/build/rn/icons';
import { FlexGrid } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import CardItem from './CardItem';

import useStore from '../../../store/store';
import { ROLES } from '../../../utils/constants';

export default function CardList() {
  const { t } = useTranslation(['homePage']);
  const { userProfile } = useStore((state) => state);
  const { role_id: roleId } = userProfile;
  const { Admin, Manager, TeamMember } = ROLES;

  const cardList = [
    {
      title: t('adminControlLabel'),
      icon: Settings,
      url: 'admin-control',
      rolesAllowed: [Admin],
    },
    {
      title: t('addTimeLabel'),
      icon: Time,
      url: 'add-time',
      rolesAllowed: [Admin, Manager, TeamMember],
    },
    {
      title: t('dashboardReportingLabel'),
      icon: ChartsLine,
      url: 'dashboard-reporting',
      rolesAllowed: [Admin, Manager],
    },
  ];

  return (
    <>
      <FlexGrid gutter={false}>
        <FlexGrid.Row>
          {cardList.map(({ title, icon, url, rolesAllowed }) => {
            if (!rolesAllowed.includes(roleId)) return null;
            return (
              <FlexGrid.Col xs={12} md={6} xl={4}>
                <CardItem title={title} icon={icon} url={url} />
              </FlexGrid.Col>
            );
          })}
        </FlexGrid.Row>
      </FlexGrid>
    </>
  );
}
