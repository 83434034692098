import { Table, Typography } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../../../../../store/store';
import { calculateTotalHoursByDayOfWeek } from '../utils';

const firstRowCellToken = {
  cellBackground: '#f4f4f7',
};

export default function TotalHoursRow() {
  const { t } = useTranslation(['timeEntry']);
  const { timesheetData } = useStore((state) => state);
  const totalHoursByDayOfWeek = calculateTotalHoursByDayOfWeek(timesheetData);
  const cellValues = ['', t('labelActualHours'), ...totalHoursByDayOfWeek, ''];
  return (
    <Table.Row>
      {cellValues.map((value, index) => (
        <Table.Cell
          key={`STATIC_ROW_CELL_${index}`}
          align="center"
          tokens={firstRowCellToken}
        >
          <Typography block variant={{ bold: true, size: 'small' }}>
            {value}
          </Typography>
        </Table.Cell>
      ))}
    </Table.Row>
  );
}
