import { useEffect } from 'react';

import { startOfWeek } from 'date-fns';
import {
  Box,
  IconButton,
  Spacer,
  Spinner,
  Table,
} from '@telus-uds/components-web';

import useStore from '../../../../store/store';
import { FIRST_DAY_OF_WEEK } from '../../../../utils/constants';

import TopSection from './components/TopSection';
import TableHeader from './components/TableHeader';
import StaticFirstRow from './components/StaticFirstRow';
import TotalHoursRow from './components/TotalHoursRow';
import RowList from './components/RowList';
import ActionBar from './components/ActionBar';
import Notification from '../../../../components/Notification';

import { getInitialTimesheetRowData } from './utils';

export default function Timesheet() {
  const {
    weekStartDate,
    timesheetData,
    setTimesheetData,
    setIsTimesheetDataValid,
    setWeekStartDate,
    timesheetLoader,
    timesheetMessage,
    setTimesheetMessage,
    fetchProjects,
    fetchTasks,
    fetchTimesheet,
  } = useStore((state) => state);

  const handleAddNewRow = () => {
    const initialRowData = getInitialTimesheetRowData(weekStartDate);
    setIsTimesheetDataValid(null);
    setTimesheetData([...timesheetData, initialRowData]);
  };

  useEffect(() => {
    // Initialize the week start date
    setWeekStartDate(
      startOfWeek(new Date(), { weekStartsOn: FIRST_DAY_OF_WEEK })
    );
    // Fetch Project List & Task List
    fetchProjects(100);
    fetchTasks(50);
  }, []);

  useEffect(() => {
    fetchTimesheet();
  }, []);

  return (
    <Spinner label={timesheetLoader?.message} show={timesheetLoader?.active}>
      <div className="time-entry-container">
        <TopSection />
        <Spacer space={4} />
        {timesheetMessage?.active && (
          <Notification data={timesheetMessage} action={setTimesheetMessage} />
        )}
        <Spacer space={2} />
        <div className="time-entry-table">
          <Table spacing="compact" text="small">
            <TableHeader />
            <Table.Body>
              <StaticFirstRow />
              <RowList />
              <TotalHoursRow />
            </Table.Body>
          </Table>
          <Spacer space={2} />
          <Box space={3}>
            <IconButton
              action="add"
              variant={{ raised: true }}
              onPress={handleAddNewRow}
            />
          </Box>
        </div>
        <Spacer space={2} />
        <ActionBar />
      </div>
    </Spinner>
  );
}
