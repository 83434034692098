import { create } from 'zustand';

/** IMPORTING ALL STORE SLICES */
import globalSlice from './slice/globalSlice';
import { projectSlice, taskSlice } from './slice/adminControlSlice';
import timesheetSlice from './slice/timesheetSlice';
import dashboardAndReportingSlice from './slice/dashboardAndReportingSlice';

const useStore = create((...a) => ({
  ...globalSlice(...a),
  ...projectSlice(...a),
  ...taskSlice(...a),
  ...timesheetSlice(...a),
  ...dashboardAndReportingSlice(...a),
}));

export default useStore;
