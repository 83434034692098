import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { FlexGrid, Spinner, Spacer } from '@telus-uds/components-web';

import Header from '../Header';
import Footer from '../Footer';
import useStore from '../../store/store';

export default function Layout({ showHeader }) {
  const { showOverlay } = useStore((state) => state);

  return (
    <div className="flex-wrapper">
      {showHeader && <Header />}
      <Spinner fullScreen label="" show={showOverlay} />
      <main>
        <FlexGrid>
          <FlexGrid.Row>
            <FlexGrid.Col xs={12} sm={12} md={12} lg={12}>
              <Spacer space={4} />
              <Outlet />
            </FlexGrid.Col>
          </FlexGrid.Row>
        </FlexGrid>
      </main>
      <Footer />
    </div>
  );
}

Layout.defaultProps = {
  showHeader: true,
};

Layout.propTypes = {
  showHeader: PropTypes.bool,
};
