import _ from 'lodash';

const isObjectWithValuePresent = (arrayOfObjects, keyToCheck, valueToCheck) =>
  _.findIndex(arrayOfObjects, { [keyToCheck]: valueToCheck });

export const getTotalEmployeeHoursAllProjectsFormattedData = (data) =>
  data
    .reduce((acc, { employeeId, employeeName, hoursWorked }) => {
      const indexOfObject = isObjectWithValuePresent(
        acc,
        'employeeId',
        employeeId
      );

      if (indexOfObject === -1) {
        acc.push({ employeeId, employeeName, hoursWorked });
      } else {
        acc[indexOfObject].hoursWorked += +hoursWorked;
      }
      return acc;
    }, [])
    .reduce(
      (acc, { employeeName, hoursWorked }) => {
        acc.categories.push(employeeName);
        acc.seriesData.push(hoursWorked);
        return acc;
      },
      { categories: [], seriesData: [] }
    );

export const getTotalEmployeeHoursPerProjectsFormattedData = (
  data,
  projectList
) => {
  const employeeProjectObj = data.reduce((acc, current) => {
    const { projectId, employeeId, hoursWorked, employeeName, projectName } =
      current;

    if (!acc.hasOwnProperty(employeeId)) {
      acc[employeeId] = {
        projects: {
          [projectId]: {
            projectName,
            hoursWorked,
          },
        },
        employeeName,
      };
    } else {
      if (!acc[employeeId].projects.hasOwnProperty(projectId)) {
        acc[employeeId].projects[projectId] = {
          projectName: projectName,
          hoursWorked: hoursWorked,
        };
      } else {
        acc[employeeId].projects[projectId].hoursWorked += hoursWorked;
      }
    }

    return acc;
  }, {});

  const dataObject = Object.entries(employeeProjectObj).reduce(
    (acc, [key, value]) => {
      const { employeeName, projects } = value;

      acc.formattedData.push({
        employeeDetails: {
          id: key,
          name: employeeName,
        },
        projects: Object.entries(projects).reduce(
          (projectAcc, [projectKey, projectData]) => {
            const { projectName, hoursWorked } = projectData;
            projectAcc.push({
              id: +projectKey,
              name: projectName,
              hoursWorked: hoursWorked,
            });
            return projectAcc;
          },
          []
        ),
      });
      acc.categories.push(value.employeeName);
      return acc;
    },
    { categories: [], formattedData: [] }
  );

  const series = projectList.map((item) => {
    const hoursByEachUser = dataObject?.formattedData.map((userData) => {
      const foundProject = userData.projects.find((row) => row.id === item.id);
      return foundProject ? foundProject.hoursWorked : 0;
    });

    return {
      name: item.label,
      data: [...hoursByEachUser],
    };
  });

  return { categories: dataObject?.categories, seriesData: series };
};

const findIndicesWithZero = (arrays) => {
  const arrayLength = arrays[0].length;
  const indicesWithZero = [];
  for (let i = 0; i < arrayLength; i++) {
    const valuesAtCurrentIndex = arrays.map((array) => array[i]);
    if (valuesAtCurrentIndex.every((value) => value === 0)) {
      indicesWithZero.push(i);
    }
  }
  return indicesWithZero;
};

const removeElementsFromIndices = (arrayData, indicesToRemove) => {
  indicesToRemove.sort((a, b) => b - a);
  for (const index of indicesToRemove) {
    if (index >= 0 && index < arrayData.length) {
      arrayData.splice(index, 1);
    }
  }
  return arrayData;
};

export const getTotalHoursByTaskPerProjectFormattedData = (data) => {
  const uniqueObj = data.reduce(
    (acc, current) => {
      const {
        employeeId,
        employeeName,
        projectId,
        projectName,
        taskId,
        taskName,
      } = current;

      if (isObjectWithValuePresent(acc.employees, 'id', employeeId) === -1)
        acc.employees.push({ id: employeeId, employeeName });

      if (isObjectWithValuePresent(acc.projects, 'id', projectId) === -1)
        acc.projects.push({ id: projectId, projectName });

      if (isObjectWithValuePresent(acc.tasks, 'id', taskId) === -1)
        acc.tasks.push({ id: taskId, taskName });

      return acc;
    },
    { employees: [], projects: [], tasks: [] }
  );

  const empProjectArray = [];
  const arrayOfArrayProject = [];
  const seriesData = [];
  uniqueObj.employees.forEach((employee) => {
    const { employeeName } = employee;
    uniqueObj.projects.forEach((project) => {
      const { projectName } = project;
      empProjectArray.push(`${employeeName} - ${projectName}`);
    });
  });

  uniqueObj.tasks.forEach((task) => {
    const { id: taskId, taskName } = task;
    const obj = {
      name: taskName,
      data: [],
    };
    uniqueObj.employees.forEach((employee, index) => {
      const { id: employeeId } = employee;

      uniqueObj.projects.forEach((project) => {
        const { id: projectId } = project;
        let totalHours = 0;
        data.forEach((dataItem) => {
          if (
            dataItem.employeeId === employeeId &&
            dataItem.projectId === projectId &&
            dataItem.taskId === taskId
          )
            totalHours += dataItem.hoursWorked;
        });
        obj.data.push(totalHours);
      });
    });

    arrayOfArrayProject.push(obj.data);
    seriesData.push(obj);
  });
  const indicesWithZero = findIndicesWithZero(arrayOfArrayProject);
  if (indicesWithZero.length > 0) {
    const newCategories = removeElementsFromIndices(
      empProjectArray,
      indicesWithZero
    );
    const newSeriesData = seriesData.map((row) => ({
      ...row,
      data: removeElementsFromIndices(row.data, indicesWithZero),
    }));

    return { categories: newCategories, seriesData: newSeriesData };
  }

  return { categories: empProjectArray, seriesData };
};

export const getTotalEmployeeHoursPerTaskFormattedData = (data, taskList) => {
  const employeeTaskObj = data.reduce((acc, current) => {
    const { taskId, taskName, employeeId, hoursWorked, employeeName } = current;

    if (!acc.hasOwnProperty(employeeId)) {
      acc[employeeId] = {
        tasks: {
          [taskId]: {
            taskName,
            hoursWorked,
          },
        },
        employeeName,
      };
    } else {
      if (!acc[employeeId].tasks.hasOwnProperty(taskId)) {
        acc[employeeId].tasks[taskId] = {
          taskName,
          hoursWorked: hoursWorked,
        };
      } else {
        acc[employeeId].tasks[taskId].hoursWorked += hoursWorked;
      }
    }

    return acc;
  }, {});

  const dataObject = Object.entries(employeeTaskObj).reduce(
    (acc, [key, value]) => {
      const { employeeName, tasks } = value;

      acc.formattedData.push({
        employeeDetails: {
          id: key,
          name: employeeName,
        },
        tasks: Object.entries(tasks).reduce(
          (taskAcc, [taskKey, projectData]) => {
            const { taskName, hoursWorked } = projectData;
            taskAcc.push({
              id: +taskKey,
              name: taskName,
              hoursWorked: hoursWorked,
            });
            return taskAcc;
          },
          []
        ),
      });
      acc.categories.push(value.employeeName);
      return acc;
    },
    { categories: [], formattedData: [] }
  );

  const series = taskList.map((item) => {
    const hoursByEachUser = dataObject?.formattedData.map((userData) => {
      const foundTask = userData.tasks.find((row) => row.id === item.id);
      return foundTask ? foundTask.hoursWorked : 0;
    });

    return {
      name: item.label,
      data: [...hoursByEachUser],
    };
  });
  return { categories: dataObject?.categories, seriesData: series };
};

export const getTotalHoursByProjectFormattedData = (data) => {
  const dataObject = data
    .reduce((acc, current) => {
      const { projectId, hoursWorked, projectName } = current;
      const itemPresentAtIndex = isObjectWithValuePresent(acc, 'id', projectId);
      if (itemPresentAtIndex === -1) {
        acc.push({
          id: projectId,
          name: projectName,
          hours: +hoursWorked,
        });
      } else {
        const obj = { ...acc[itemPresentAtIndex] };
        obj.hours += +hoursWorked;
        acc[itemPresentAtIndex] = obj;
      }
      return acc;
    }, [])
    .map(({ name, hours }) => ({ name, y: hours }));
  return dataObject;
};

export const getTotalHoursByTaskFormattedData = (data) => {
  const dataObject = data
    .reduce((acc, current) => {
      const { taskId, hoursWorked, taskName } = current;
      const itemPresentAtIndex = isObjectWithValuePresent(acc, 'id', taskId);
      if (itemPresentAtIndex === -1) {
        acc.push({
          id: taskId,
          name: taskName,
          hours: +hoursWorked,
        });
      } else {
        const obj = { ...acc[itemPresentAtIndex] };
        obj.hours += +hoursWorked;
        acc[itemPresentAtIndex] = obj;
      }
      return acc;
    }, [])
    .map(({ name, hours }) => ({ name, y: hours }));
  return dataObject;
};

/** FUNCTIONS FOR CSV DATA */

export const getTotalEmployeeHoursAllProjectsCsvData = (data) =>
  data
    .reduce(
      (acc, current) => {
        const { employeeId, employeeName, hoursWorked } = current;
        const indexOfEmployeeArray = _.findIndex(
          acc,
          (innerArray) => innerArray[0] === employeeId
        );
        if (indexOfEmployeeArray === -1)
          acc.push([employeeId, employeeName, hoursWorked]);
        else acc[indexOfEmployeeArray][2] += +hoursWorked;
        return acc;
      },
      [['Employee Id', 'Employee Name', 'Total Hours Worked']]
    )
    .map((innerArray) => innerArray.slice(1));

export const getTotalEmployeeHoursPerProjectsCsvData = (data) =>
  data
    .reduce(
      (acc, current) => {
        const {
          employeeId,
          employeeName,
          projectId,
          projectName,
          hoursWorked,
        } = current;
        const indexOfEmployeeProjectArray = _.findIndex(
          acc,
          (innerArray) =>
            innerArray[0] === employeeId && innerArray[2] === projectId
        );
        if (indexOfEmployeeProjectArray === -1)
          acc.push([
            employeeId,
            employeeName,
            projectId,
            projectName,
            hoursWorked,
          ]);
        else acc[indexOfEmployeeProjectArray][4] += +hoursWorked;
        return acc;
      },
      [
        [
          'Employee Id',
          'Employee Name',
          'Project Id',
          'Project Name',
          'Total Hours Worked',
        ],
      ]
    )
    .map((innerArray) => {
      innerArray.splice(0, 1);
      innerArray.splice(1, 1);
      return innerArray;
    });

export const getTotalEmployeeHoursPerTaskCsvData = (data) =>
  data
    .reduce(
      (acc, current) => {
        const { employeeId, employeeName, taskId, taskName, hoursWorked } =
          current;
        const indexOfEmployeeTaskArray = _.findIndex(
          acc,
          (innerArray) =>
            innerArray[0] === employeeId && innerArray[2] === taskId
        );
        if (indexOfEmployeeTaskArray === -1)
          acc.push([employeeId, employeeName, taskId, taskName, hoursWorked]);
        else acc[indexOfEmployeeTaskArray][4] += +hoursWorked;
        return acc;
      },
      [
        [
          'Employee Id',
          'Employee Name',
          'Activity Id',
          'Activity Name',
          'Total Hours Worked',
        ],
      ]
    )
    .map((innerArray) => {
      innerArray.splice(0, 1);
      innerArray.splice(1, 1);
      return innerArray;
    });

export const getTotalHoursByProjectCsvData = (data) => {
  const formattedData = data.reduce(
    (acc, current) => {
      const { projectId, projectName, hoursWorked } = current;
      const indexOfProjectArray = _.findIndex(
        acc,
        (innerArray) => innerArray[0] === projectId
      );
      if (indexOfProjectArray === -1)
        acc.push([projectId, projectName, hoursWorked]);
      else acc[indexOfProjectArray][2] += +hoursWorked;
      return acc;
    },
    [['Project Id', 'Project Name', 'Total Hours']]
  );
  const total = formattedData.reduce((acc, current) => {
    if (typeof current[2] === 'number') acc += current[2];
    return acc;
  }, 0);
  const finalFormattedData = formattedData.map((innerArray) => {
    innerArray.splice(0, 1);
    if (typeof innerArray[1] === 'number') {
      const percentage = (innerArray[1] / total) * 100;
      innerArray.push(parseFloat(percentage.toFixed(1)));
    } else innerArray.push('% of total project hours');
    return innerArray;
  });

  return finalFormattedData;
};

export const getTotalHoursByTaskCsvData = (data) => {
  const formattedData = data.reduce(
    (acc, current) => {
      const { taskId, taskName, hoursWorked } = current;
      const indexOfTaskArray = _.findIndex(
        acc,
        (innerArray) => innerArray[0] === taskId
      );
      if (indexOfTaskArray === -1) acc.push([taskId, taskName, hoursWorked]);
      else acc[indexOfTaskArray][2] += +hoursWorked;
      return acc;
    },
    [['Activity Id', 'Activity Name', 'Total Hours']]
  );
  const total = formattedData.reduce((acc, current) => {
    if (typeof current[2] === 'number') acc += current[2];
    return acc;
  }, 0);
  const finalFormattedData = formattedData.map((innerArray) => {
    innerArray.splice(0, 1);
    if (typeof innerArray[1] === 'number') {
      const percentage = (innerArray[1] / total) * 100;
      innerArray.push(parseFloat(percentage.toFixed(1)));
    } else innerArray.push('% of total activity hours');
    return innerArray;
  });

  return finalFormattedData;
};

export const getTotalHoursByTaskPerProjectCsvData = (data) =>
  data.reduce(
    (acc, current) => {
      const { employeeName, hoursWorked, projectName, taskName } = current;
      acc.push([employeeName, projectName, taskName, hoursWorked]);
      return acc;
    },
    [['Employee Name', 'Project Name', 'Activity Name', 'Hours Worked']]
  );

export const getRawCsvData = (data) =>
  data.reduce(
    (acc, current) => {
      const { employeeName, hoursWorked, projectName, taskName, workDate } =
        current;
      acc.push([employeeName, projectName, taskName, hoursWorked, workDate]);
      return acc;
    },
    [
      [
        'Employee Name',
        'Project Name',
        'Activity Name',
        'Hours Worked',
        'Work Date',
      ],
    ]
  );
