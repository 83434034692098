import { useState } from 'react';

import { Box, Button, Spacer, TextInput } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import { postRequest } from '../../../../../config/apiClient';
import useStore from '../../../../../store/store';

export default function AddProject() {
  const { t } = useTranslation(['adminControl', 'common']);
  const {
    newProjectName,
    setNewProjectName,
    fetchProjects,
    setProjectMessage,
  } = useStore((state) => state);
  const [addProjectError, setAddProjectError] = useState(false);

  const addProject = async () => {
    if (!newProjectName.trim()) {
      setAddProjectError(true);
      return;
    }

    try {
      const result = await postRequest('project', { name: newProjectName });

      if (result) {
        setProjectMessage({
          active: true,
          type: 'success',
          message: 'Project added successfully',
        });
        setNewProjectName('');
        fetchProjects();
      }
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  return (
    <Box variant={{ background: 'light' }} space={4}>
      <TextInput
        label={t('adminControl:labelProjectName')}
        value={newProjectName}
        onChange={(name) => {
          setNewProjectName(name);
          setAddProjectError(false);
        }}
        validation={addProjectError ? 'error' : ''}
        feedback={addProjectError ? 'Project name cannot be blank.' : ''}
      />
      <Spacer space={3} />
      <Button onPress={addProject}>{t('common:labelAdd')}</Button>
    </Box>
  );
}
