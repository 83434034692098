import {
  Responsive,
  Spinner,
  Spacer,
  Table,
  FlexGrid,
} from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../../../../../store/store';

import Pagination from '../../../../../components/Pagination';
import ProjectListRow from './ProjectListRow';

export default function ProjectList() {
  const { t } = useTranslation(['adminControl']);
  const {
    loadingProjects,
    projectList,
    currentPageNumberProject,
    setCurrentPageNumberProject,
    totalProjects,
  } = useStore((state) => state);

  return (
    <Spinner label="Fetching projects..." show={loadingProjects}>
      <Table>
        {/* For small screens hide project id cell */}
        <Responsive min="xs" max="sm">
          <Table.Header>
            <Table.Cell>{t('labelProjectName')}</Table.Cell>
            <Table.Cell>{t('labelAction')}</Table.Cell>
          </Table.Header>
        </Responsive>
        <Responsive min="md" max="xl">
          <Table.Header>
            <Table.Cell>{t('labelProjectId')}</Table.Cell>
            <Table.Cell>{t('labelProjectName')}</Table.Cell>
            <Table.Cell>{t('labelAction')}</Table.Cell>
          </Table.Header>
        </Responsive>
        <Table.Body>
          {projectList.map((projectData) => (
            <ProjectListRow key={projectData?.id} projectData={projectData} />
          ))}
        </Table.Body>
      </Table>
      <Spacer space={5} />
      <FlexGrid>
        <FlexGrid.Row>
          <FlexGrid.Col>
            <Pagination
              total={totalProjects}
              currentPage={currentPageNumberProject}
              setPage={setCurrentPageNumberProject}
            />
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </Spinner>
  );
}
