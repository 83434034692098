import Row from './Row';

import useStore from '../../../../../store/store';

export default function RowList() {
  const { timesheetData } = useStore((state) => state);

  return (
    <>
      {timesheetData.map((data, index) => (
        <Row key={`TIMESHEET_ROW_${index}`} rowData={data} rowIndex={index} />
      ))}
    </>
  );
}
