import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';

import Layout from '../components/Layout';

/** IMPORT PAGES */
import Home from '../pages/HomePage';
import AdminControl from '../pages/AdminControl';
import AddTime from '../pages/AddTime';
import DashboardAndReporting from '../pages/DashboardAndReporting';
import Login from '../pages/Login';
import Error from '../pages/Error';

import LocaleCheck from './localeCheck';
import ProtectedRoutes from './protectedRoutes';

import { ROLES } from '../utils/constants';

export default function ProjectRoutes() {
  const { Admin, Manager, TeamMember } = ROLES;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<LocaleCheck />}>
          {/** Public routes */}
          <Route path="/:locale" element={<Layout showHeader={false} />}>
            <Route path="login" element={<Login />} />
            <Route path="error" element={<Error />} />
          </Route>

          {/** Protected routes */}
          <Route
            path="/:locale"
            element={
              <ProtectedRoutes allowedRoles={[Admin, Manager, TeamMember]} />
            }
          >
            <Route index element={<Home />} />
          </Route>
          <Route
            path="/:locale"
            element={<ProtectedRoutes allowedRoles={[Admin]} />}
          >
            <Route path="admin-control" element={<AdminControl />} />
          </Route>
          <Route
            path="/:locale"
            element={
              <ProtectedRoutes allowedRoles={[Admin, Manager, TeamMember]} />
            }
          >
            <Route path="add-time" element={<AddTime />} />
          </Route>
          <Route
            path="/:locale"
            element={<ProtectedRoutes allowedRoles={[Admin, Manager]} />}
          >
            <Route
              path="dashboard-reporting"
              element={<DashboardAndReporting />}
            />
          </Route>

          {/** missing routes - 404 */}
          <Route path="*" element={<Navigate replace to="/en" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
