import {
  addDays,
  eachDayOfInterval,
  endOfWeek,
  format,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../config/apiClient';
import {
  validateTimeSheet,
  getInitialTimesheetRowData,
  getTransformedResponseTimesheetData,
  getTransformedPayloadData,
  savedDataInCurrentTimesheet,
  getTimesheetRowIds,
  getTransformedPreviousWeekResponse,
  calculateTotalHoursByDayOfWeek,
} from '../../pages/AddTime/components/Timesheet/utils';
import { FIRST_DAY_OF_WEEK } from '../../utils/constants';

const timesheetSlice = (set, get) => ({
  datePickerValue: null,
  setDatePickerValue: (value) => {
    const selectedDate = new Date(value);
    const startOfTheWeek = startOfWeek(selectedDate, {
      weekStartsOn: FIRST_DAY_OF_WEEK,
    });
    set(() => ({ datePickerValue: value }));
    get().setWeekStartDate(new Date(startOfTheWeek));
  },
  weekEndDate: null,
  weekStartDate: null,
  setWeekStartDate: (date) => {
    get().setTimesheetMessage({
      active: false,
      message: '',
    });
    const weekEndDate = endOfWeek(new Date(date), {
      weekStartsOn: FIRST_DAY_OF_WEEK,
    });
    set(() => ({ weekStartDate: date, weekEndDate }));
    get().fetchTimesheet();
  },
  inEditMode: false,
  setInEditMode: (value) => set(() => ({ inEditMode: value })),
  timesheetLoader: { active: false, message: 'loading...' },
  setTimesheetLoader: (value) => set(() => ({ timesheetLoader: { ...value } })),
  timesheetMessage: { active: false, type: '', message: '' },
  setTimesheetMessage: (value) =>
    set(() => ({ timesheetMessage: { ...value } })),
  timesheetData: [],
  setTimesheetData: (data) => set(() => ({ timesheetData: [...data] })),
  isTimesheetDataValid: null,
  setIsTimesheetDataValid: (value) =>
    set(() => ({ isTimesheetDataValid: value })),
  fetchTimesheet: async (
    startDate = '',
    endDate = '',
    fetchingPreviousWeekData = false
  ) => {
    if (get().weekStartDate && get().weekEndDate) {
      get().setTimesheetLoader({
        active: true,
        message: 'Fetching timesheet...',
      });
      const formattedStartDate = format(
        startDate || get().weekStartDate,
        'yyyy-MM-dd'
      );
      const formattedEndDate = format(
        endDate || get().weekEndDate,
        'yyyy-MM-dd'
      );

      try {
        const response = await getRequest(
          `timesheet/${formattedStartDate}/${formattedEndDate}`
        );
        const {
          data: { data },
        } = response;

        if (response && data && data.length > 0) {
          const transformedResponse = fetchingPreviousWeekData
            ? getTransformedPreviousWeekResponse(data, get().weekStartDate)
            : getTransformedResponseTimesheetData(data);
          get().setTimesheetData(transformedResponse);
        } else {
          const initialRowData = getInitialTimesheetRowData(
            get().weekStartDate
          );
          get().setTimesheetData([initialRowData]);
        }
      } catch (error) {
        const initialRowData = getInitialTimesheetRowData(get().weekStartDate);
        get().setTimesheetData([initialRowData]);
        get().setTimesheetMessage({
          active: true,
          type: 'error',
          message: 'Error fetching timesheet.',
        });
      } finally {
        get().setTimesheetLoader({
          active: false,
          message: '',
        });
      }
    }
  },
  saveTimesheet: async () => {
    const isValid = validateTimeSheet(get().timesheetData);
    if (!isValid) get().setIsTimesheetDataValid(isValid);
    else {
      try {
        get().setTimesheetLoader({
          active: true,
          message: 'Updating timesheet...',
        });
        const existingTimesheetToUpdate = get().timesheetData.filter(
          (row) => row.id
        );
        const newTimesheetToAdd = get().timesheetData.filter((row) => !row.id);
        let postPromise = true;
        let putPromise = true;
        if (newTimesheetToAdd.length > 0) {
          postPromise = postRequest('timesheet', {
            timesheets: getTransformedPayloadData(newTimesheetToAdd),
          });
        }
        if (existingTimesheetToUpdate.length > 0) {
          putPromise = putRequest('timesheet', {
            timesheets: getTransformedPayloadData(existingTimesheetToUpdate),
          });
        }
        const [postResult, putResult] = await Promise.all(
          [postPromise, putPromise].filter(Boolean)
        );
        if ((postResult, putResult)) {
          get().setTimesheetMessage({
            active: true,
            type: 'success',
            message: 'Timesheet was saved successfully!',
          });
          get().fetchTimesheet();
        }
      } catch (error) {
        get().fetchTimesheet();
        get().setTimesheetMessage({
          active: true,
          type: 'error',
          message: 'Error occurred while saving timesheet.',
        });
      } finally {
        get().setTimesheetLoader({
          active: false,
          message: '',
        });
      }
    }
  },
  removeTimesheetRowData: async (
    id,
    showConfirmation = true,
    fetchUpdatedDataAfterDelete = true
  ) => {
    let timesheetIds = [];
    if (!Array.isArray(id)) timesheetIds.push(id);
    else timesheetIds = [...id];

    try {
      get().setTimesheetLoader({
        active: true,
        message: 'Updating timesheet...',
      });
      const deletePromises = timesheetIds.map((timesheetId) =>
        deleteRequest(`timesheet/${timesheetId}`)
      );
      const deleteResult = await Promise.all(deletePromises);

      if (deleteResult && showConfirmation)
        get().setTimesheetMessage({
          active: true,
          type: 'success',
          message: 'Timesheet Updated successfully!',
        });
      if (deleteResult && fetchUpdatedDataAfterDelete) get().fetchTimesheet();
    } catch (error) {
      get().setTimesheetMessage({
        active: true,
        type: 'error',
        message: 'Error occurred while updating timesheet.',
      });
    } finally {
      get().setTimesheetLoader({
        active: false,
        message: '',
      });
    }
  },
  copyPreviousWeekTimesheet: async () => {
    get().setTimesheetLoader({
      active: true,
      message: 'Fetching previous week timesheet...',
    });

    try {
      const previousWeek = subWeeks(get().weekStartDate, 1);
      const startOfPreviousWeek = startOfWeek(previousWeek, {
        weekStartsOn: FIRST_DAY_OF_WEEK,
      });
      const endOfPreviousWeek = endOfWeek(previousWeek, {
        weekStartsOn: FIRST_DAY_OF_WEEK,
      });

      const timesheetData = get().timesheetData;
      const currentTimesheetHasData =
        savedDataInCurrentTimesheet(timesheetData);
      /**
       * Fetching the previous weeks timesheet to check if data exists
       */
      const previousWeekResult = await getRequest(
        `timesheet/${format(startOfPreviousWeek, 'yyyy-MM-dd')}/${format(
          endOfPreviousWeek,
          'yyyy-MM-dd'
        )}`
      );

      /**
       * If no data exists then show message and return
       */
      if (!previousWeekResult?.data?.data?.length) {
        get().setTimesheetMessage({
          active: true,
          type: 'warning',
          message: currentTimesheetHasData
            ? 'No data found in previous weeks timesheet. Your current timesheet hasn`t been deleted'
            : 'No data found in previous weeks timesheet',
        });
        get().setTimesheetLoader({
          active: false,
          message: '',
        });
        return;
      }

      /**
       * If previous week data exists then check if current week has any saved data
       * if yes then delete all the rows
       */

      if (currentTimesheetHasData) {
        const timesheetRowIds = getTimesheetRowIds(timesheetData);
        await get().removeTimesheetRowData(timesheetRowIds, false, false);
      }

      /**
       * Transform the previous week data fetched before and update its weekDate
       * and workDates to match the current weeks date
       */
      const transformedPreviousData = getTransformedPreviousWeekResponse(
        previousWeekResult.data.data,
        startOfWeek(get().weekStartDate, { weekStartsOn: FIRST_DAY_OF_WEEK })
      );

      /**
       * Set the transformed data to current week timesheet state
       */
      get().setTimesheetData(transformedPreviousData);
      get().setTimesheetMessage({
        active: true,
        type: 'warning',
        message:
          'Save your data before navigating away or else the data would be lost',
      });
    } catch (error) {
      get().setTimesheetMessage({
        active: true,
        type: 'error',
        message: 'Error occurred while fetching previous week timesheet.',
      });
    } finally {
      get().setTimesheetLoader({
        active: false,
        message: '',
      });
    }
  },
  downloadTimesheetPdf: () => {
    const pdf = new jsPDF({ orientation: 'landscape' });
    const { first_name: firstName, last_name: lastName } = get().userProfile;
    const formattedStartDate = format(
      new Date(get().weekStartDate),
      'MMM d, yyyy'
    );
    const formattedEndDate = format(new Date(get().weekEndDate), 'MMM d, yyyy');
    const totalHoursByDayOfWeek = calculateTotalHoursByDayOfWeek(
      get().timesheetData
    );

    // Users Name
    pdf.setFont('helvetica', 'normal');
    pdf.setFontSize(12);
    pdf.text('Timesheet For:', 10, 10);
    pdf.setFont('helvetica', 'bold');
    pdf.setFontSize(14);
    pdf.text(`${firstName} ${lastName}`, 40, 10);

    // Timesheet period
    pdf.setFont('helvetica', 'normal');
    pdf.setFontSize(12);
    pdf.text('Date Period:', 10, 20);
    pdf.setFont('helvetica', 'bold');
    pdf.setFontSize(14);
    pdf.text(`${formattedStartDate} - ${formattedEndDate}`, 35, 20);

    // Timesheet period
    pdf.setFont('helvetica', 'normal');
    pdf.setFontSize(12);
    pdf.text('Total hours worked:', 10, 30);
    pdf.setFont('helvetica', 'bold');
    pdf.setFontSize(14);
    pdf.text(`${totalHoursByDayOfWeek[0]}`, 48, 30);

    const datesOfTheWeek = eachDayOfInterval({
      start: get().weekStartDate,
      end: addDays(get().weekStartDate, 6),
    }).map((date) => format(new Date(date), 'E - MMM d'));

    const tableHeader = ['Project', 'Activity', ...datesOfTheWeek, 'Total'];
    const projects = get().projectList;
    const tasks = get().taskList;

    totalHoursByDayOfWeek.push(totalHoursByDayOfWeek.shift());

    const bodyData = get().timesheetData.reduce((acc, current) => {
      const { projectId, taskId, data } = current;
      const rowArr = [];
      const projectName = projects.find((item) => item.id === projectId)?.name;
      const taskName = tasks.find((item) => item.id === taskId)?.name;
      let rowTotal = 0;

      rowArr.push(projectName);
      rowArr.push(taskName);

      data.forEach((dateItem) => {
        rowTotal += +dateItem.hoursWorked;
        rowArr.push(dateItem.hoursWorked);
      });
      rowArr.push(rowTotal);
      acc.push(rowArr);
      return acc;
    }, []);

    bodyData.push(['', 'Total Hours', ...totalHoursByDayOfWeek]);

    pdf.autoTable({
      head: [tableHeader],
      body: bodyData,
      startY: 40,
      margin: { top: 0, right: 10, bottom: 0, left: 10 },
      theme: 'grid',
      headStyles: {
        fillColor: [244, 244, 247],
        textColor: [0, 0, 0],
        borderColor: [0, 0, 0],
      },
    });

    const options = {
      autoPrint: {
        variant: 'javascript',
      },
    };

    pdf.output('dataurlnewwindow', options);
  },
});

export default timesheetSlice;
