import _ from 'lodash';

export const getPathName = () => {
  return window.location.pathname;
};
export const getLocale = () => {
  const currentPath = window.location.pathname;
  const locale = currentPath.split('/')[1];
  if (!['en', 'fr'].includes(locale)) return 'en';
  return locale;
};
export const setLocalStorage = (key, value) => {
  if (key && value) window.localStorage.setItem(key, value);
};
export const getLocalStorage = (key) => window.localStorage.getItem(key);
export const removeLocalStorage = (key) => window.localStorage.removeItem(key);

// Removing all data with 0 hours worked
export const removeZeroHoursWorked = (data) => data.reduce((acc, current) => {
  if (+current.hoursWorked > 0) acc.push(current);
  return acc;
}, []);

export const getFiltersData = (data, id, name) => data.reduce((acc, record) => {
  const indexOfObject = _.findIndex(acc, {
    id: record[id]
  });
  if (indexOfObject === -1) acc.push({ id: record[id], label: record[name] });
  return acc;
}, []);
