import useStore from '../../../../../store/store';
import { getTotalHoursByTaskFormattedData } from '../util';

import Chart from './Chart';

export default function TotalHoursByTask() {
  const { reportingData } = useStore();

  if (reportingData.length === 0) return null;

  const seriesData = getTotalHoursByTaskFormattedData(reportingData);

  const options = {
    chart: {
      type: 'pie',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    tooltip: {
      formatter: function () {
        return `Task: <b>${this.point.name}</b></br>Total Hours: <b>${
          this.point.y
        }</b></br>Percentage: <b>${
          Math.round(this.point.percentage * 100) / 100
        }%</b>`;
      },
    },
    plotOptions: {
      pie: {
        size: '90%', // Adjust the size as needed
      },
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: [
          {
            enabled: true,
            distance: 20,
          },
          {
            enabled: true,
            distance: -40,
            format: '{point.percentage:.1f}%',
            style: {
              fontSize: '1em',
              textOutline: 'none',
              opacity: 0.7,
            },
            filter: {
              operator: '>',
              property: 'percentage',
              value: 10,
            },
          },
        ],
      },
    },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        data: seriesData,
      },
    ],
  };

  return (
    <Chart
      heading="Total Weekly Hours Spent by Activity"
      options={options}
      downloadCsvFor="TOTAL_HOURS_TASK"
    />
  );
}
