import { useEffect } from 'react';

import { FlexGrid, Spacer } from '@telus-uds/components-web';

import useStore from '../../../../store/store';

import AddTask from './components/AddTask';
import TaskList from './components/TaskList';
import Notification from '../../../../components/Notification';

export default function Tasks() {
  const { fetchTasks, taskMessage, setTaskMessage } = useStore(
    (state) => state
  );

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <>
      <Spacer space={6} />
      <FlexGrid>
        <FlexGrid.Row>
          <FlexGrid.Col sm={12} md={12} lg={4}>
            <AddTask />
            <Spacer space={6} />
          </FlexGrid.Col>
          <FlexGrid.Col sm={12} md={12} lg={8}>
            {taskMessage?.active && (
              <>
                <Notification data={taskMessage} action={setTaskMessage} />
                <Spacer space={4} />
              </>
            )}
            <TaskList />
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </>
  );
}
