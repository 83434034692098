import { useState } from 'react';

import { Files, Print } from '@telus-uds/palette-allium/build/web/icons';
import { Button } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../../../../../store/store';
import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal';

import { savedDataInCurrentTimesheet, validateTimeSheet } from '../utils';

export default function ActionBar() {
  const { t } = useTranslation(['common', 'timeEntry']);
  const {
    timesheetData,
    saveTimesheet,
    copyPreviousWeekTimesheet,
    downloadTimesheetPdf,
  } = useStore((state) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isDataSavedForCurrentTimesheet =
    savedDataInCurrentTimesheet(timesheetData);
  const isTimesheetValid = validateTimeSheet(timesheetData);

  const modalData = {
    isOpen: isModalOpen,
    heading: 'Please confirm',
    bodyText:
      'You have saved data in your current timesheet. Proceeding with copying previous weeks data will result in lose of current weeks saved data. Are you sure you want to proceed?',
    onClose: setIsModalOpen,
    onConfirm: () => {
      setIsModalOpen(false);
      copyPreviousWeekTimesheet();
    },
  };

  const handleCopyPreviousWeekTimesheet = () => {
    if (!isDataSavedForCurrentTimesheet) copyPreviousWeekTimesheet();
    else setIsModalOpen(true);
  };

  return (
    <>
      <div className="time-entry-action-bar">
        <div className="flex gap-1-5">
          <Button
            variant={{ priority: 'high' }}
            disabled={!isTimesheetValid}
            onPress={saveTimesheet}
          >
            {t('common:labelSave')}
          </Button>
          <Button
            icon={Print}
            disabled={!isDataSavedForCurrentTimesheet}
            onPress={downloadTimesheetPdf}
          >
            {t('common:labelPrint')}
          </Button>
          <Button icon={Files} onPress={handleCopyPreviousWeekTimesheet}>
            {t('timeEntry:labelCopyFromPreviousWeek')}
          </Button>
        </div>
      </div>
      <ConfirmationModal data={modalData} />
    </>
  );
}
