import { Button, TextButton, Responsive } from '@telus-uds/components-web';
import { CSVLink } from 'react-csv';

import useStore from '../../../store/store';
import { getRawCsvData } from './Reports/util';

export default function DownloadRawData() {
  const { reportingData } = useStore((state) => state);
  if (reportingData.length === 0) return null;

  const csvData = getRawCsvData(reportingData);

  return (
    <div className="download-raw-data">
      {csvData && (
        <CSVLink data={csvData} filename={`raw-data.csv`}>
          <Responsive min="xs" max="md">
            <TextButton>Download Raw CSV Data</TextButton>
          </Responsive>
          <Responsive min="lg" max="xl">
            <Button>Download Raw CSV Data</Button>
          </Responsive>
        </CSVLink>
      )}
    </div>
  );
}
