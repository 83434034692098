import axios from 'axios';
import { getLocalStorage } from '../utils/utils';

const axiosClientMySof = axios.create();

axiosClientMySof.defaults.baseURL = process.env.REACT_APP_API_MYSOF;

const config = () => ({
  headers: {
    platform: getLocalStorage('userPlatform'),
    Authorization: `Bearer ${getLocalStorage('accessToken')}`,
    'Access-Control-Allow-Origin': '*',
  },
});

export function getMySofRequest(URL, options) {
  return axiosClientMySof
    .get(`/${URL}`, { ...config(), ...(options && options) })
    .then((response) => response);
}

export function postMySofRequest(URL, payload, options) {
  return axiosClientMySof
    .post(`/${URL}`, payload, { ...config(), ...(options && options) })
    .then((response) => response);
}

export function putMySofRequest(URL, payload) {
  return axiosClientMySof
    .put(`/${URL}`, payload, config())
    .then((response) => response);
}

export function deleteMySofRequest(URL) {
  return axiosClientMySof
    .delete(`/${URL}`, config())
    .then((response) => response);
}
