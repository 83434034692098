import { StackView, Typography } from '@telus-uds/components-web';

import useStore from '../../../../store/store';

import TotalEmployeeHoursAllProjects from './components/TotalEmployeeHoursAllProjects';
import TotalEmployeeHoursPerProjects from './components/TotalEmployeeHoursPerProjects';
import TotalEmployeeHoursPerTask from './components/TotalEmployeeHoursPerTask';
import TotalHoursByProject from './components/TotalHoursByProject';
import TotalHoursByTask from './components/TotalHoursByTask';
import TotalHoursByTaskPerProject from './components/TotalHoursByTaskPerProject';

export default function Reports() {
  const { reportingData } = useStore((state) => state);

  return (
    <>
      {reportingData.length === 0 ? (
        <div className="reporting-nodata">
          <Typography block variant={{ size: 'h3' }}>
            No Data Found
          </Typography>
        </div>
      ) : (
        <StackView space={2}>
          <TotalEmployeeHoursAllProjects />
          <TotalEmployeeHoursPerProjects />
          <TotalEmployeeHoursPerTask />
          <TotalHoursByProject />
          <TotalHoursByTask />
          <TotalHoursByTaskPerProject />
        </StackView>
      )}
    </>
  );
}
