import { Table } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

const firstRowCellToken = {
  cellBackground: '#f4f4f7',
};

export default function StaticFirstRow() {
  const { t } = useTranslation(['timeEntry']);
  const cellValues = [
    '',
    t('labelTargetHours'),
    '37.5',
    '7.5',
    '7.5',
    '7.5',
    '7.5',
    '7.5',
    '0.0',
    '0.0',
    '',
  ];
  return (
    <Table.Row>
      {cellValues.map((value, index) => (
        <Table.Cell
          key={`STATIC_ROW_CELL_${index}`}
          align="center"
          tokens={firstRowCellToken}
        >
          {value}
        </Table.Cell>
      ))}
    </Table.Row>
  );
}
