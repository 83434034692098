import { Delete } from '@telus-uds/palette-allium/build/web/icons';
import { IconButton, Table } from '@telus-uds/components-web';

import useStore from '../../../../../store/store';

export default function Row({ rowData, rowIndex }) {
  const {
    timesheetData,
    setTimesheetData,
    removeTimesheetRowData,
    isTimesheetDataValid,
    projectList,
    taskList,
  } = useStore((state) => state);

  const { id, projectId, taskId, data: dateList, isValid } = rowData;

  const rowValidationCheckInvalid =
    isTimesheetDataValid !== null && !isTimesheetDataValid && !isValid;

  const totalRowTime = dateList.reduce((acc, current) => {
    return acc + +current.hoursWorked;
  }, 0);

  const atleastOneTimeEntry = (datesArr) =>
    datesArr.some((entry) => entry.hoursWorked !== 0);

  const isRowDataValid = (timesheetCopy) => {
    return (
      timesheetCopy.projectId !== 0 &&
      timesheetCopy.taskId !== 0 &&
      atleastOneTimeEntry(timesheetCopy.data)
    );
  };

  const handleInputChanges = (value, changeFor, type = '', dateIndex = 0) => {
    const timesheetCopy = [...timesheetData];
    if (changeFor === 'PROJECT') timesheetCopy[rowIndex].projectId = +value;
    if (changeFor === 'TASK') timesheetCopy[rowIndex].taskId = +value;
    if (changeFor === 'TIME_ENTRY') {
      if (type === 'CLICK' && +value === 0)
        timesheetCopy[rowIndex].data[dateIndex].hoursWorked = '';
      if (type === 'BLUR' && (value === '' || value == null))
        timesheetCopy[rowIndex].data[dateIndex].hoursWorked = 0;
      if (type === 'CHANGE')
        timesheetCopy[rowIndex].data[dateIndex].hoursWorked = +value;
    }

    // Update the isValid flag
    timesheetCopy[rowIndex].isValid = isRowDataValid(timesheetCopy[rowIndex]);

    setTimesheetData(timesheetCopy);
  };

  const removeRow = () => {
    if (id) removeTimesheetRowData(id);
    const timesheetCopy = [...timesheetData];
    timesheetCopy.splice(rowIndex, 1);
    setTimesheetData(timesheetCopy);
  };

  return (
    <Table.Row>
      <Table.Cell align="center" type="rowHeading">
        <select
          className={`time-entry-table__select-box ${
            rowValidationCheckInvalid && rowData.projectId === 0
              ? 'invalid'
              : ''
          }`}
          onChange={(e) => handleInputChanges(e.target.value, 'PROJECT')}
        >
          <option value="" disabled selected>
            Select Project
          </option>
          {projectList.length &&
            projectList.map(({ id, name }) => (
              <option
                key={`project-option-${name}-${id}`}
                value={id}
                selected={projectId === id}
              >
                {name}
              </option>
            ))}
        </select>
      </Table.Cell>
      <Table.Cell align="center" type="rowHeading">
        <select
          className={`time-entry-table__select-box ${
            rowValidationCheckInvalid && rowData.taskId === 0 ? 'invalid' : ''
          }`}
          onChange={(e) => handleInputChanges(e.target.value, 'TASK')}
        >
          <option value="" disabled selected>
            Select Activity
          </option>
          {taskList.length &&
            taskList.map(({ id, name }) => (
              <option
                key={`activity-option-${name}-${id}`}
                value={id}
                selected={taskId === id}>
                {name}
              </option>
            ))}
        </select>
      </Table.Cell>
      <Table.Cell align="center">{totalRowTime}</Table.Cell>
      {rowData?.data?.map(({ hoursWorked }, index) => (
        <Table.Cell key={`TIME_ENTRY_DATE_INDEX_${index}`} align="center">
          <input
            className={`time-entry-table__input-box ${
              rowValidationCheckInvalid && !atleastOneTimeEntry()
                ? 'invalid'
                : ''
            }`}
            min="0"
            type="number"
            value={hoursWorked}
            onChange={(e) => {
              const inputValue = Number(e.target.value);
              if (!isNaN(inputValue) && inputValue >= 0) {
                handleInputChanges(inputValue, 'TIME_ENTRY', 'CHANGE', index);
              }
            }}
            onClick={(e) => {
              const inputValue = Number(e.target.value);
              if (!isNaN(inputValue) && inputValue >= 0) {
                handleInputChanges(inputValue, 'TIME_ENTRY', 'CLICK', index);
              }
            }}
            onBlur={(e) => {
              const inputValue = Number(e.target.value);
              if (!isNaN(inputValue) && inputValue >= 0) {
                handleInputChanges(inputValue, 'TIME_ENTRY', 'BLUR', index);
              }
            }}
          />
        </Table.Cell>
      ))}
      <Table.Cell>
        {rowIndex === 0 && !id ? (
          ''
        ) : (
          <IconButton
            icon={Delete}
            variant={{ raised: true }}
            onPress={removeRow}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
}
