import { TextButton, Typography } from '@telus-uds/components-web';

import CustomCheckbox from '../CustomCheckbox';

export default function MultiSelectFilter({
  label,
  items,
  selectedItems,
  setSelectedItems,
}) {
  const filterName = `filter-${label}`;
  
  const handleToggleItem = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const handleSelectAll = () => {
    const allItemIds = items.map((item) => item.id);
    setSelectedItems(allItemIds);
  };

  const handleClearAll = () => {
    setSelectedItems([]);
  };

  return (
    <>
      <Typography block variant={{ size: 'h4', colour: 'light' }}>
        {label}
      </Typography>
      <div className="filter-list">
        {items.map(({ label, id }) => (
          <CustomCheckbox
            key={`${filterName}-${id}`}
            id={id}
            label={label}
            isChecked={selectedItems.includes(id)}
            onCheck={handleToggleItem}
          />
        ))}
      </div>
      <div className="filter-action">
        <TextButton onPress={handleSelectAll}>Select all</TextButton>
        <TextButton onPress={handleClearAll}>Clear all</TextButton>
      </div>
    </>
  );
}
