import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Checkmark,
  Close,
  Edit,
  Delete,
} from '@telus-uds/palette-allium/build/rn/icons';
import {
  Responsive,
  IconButton,
  Table,
  Typography,
  Skeleton,
  Box,
} from '@telus-uds/components-web';

import useStore from '../../../../../store/store';
import { putRequest, deleteRequest } from '../../../../../config/apiClient';

export default function TaskListRow({ taskData }) {
  const { id, name, canDeleted } = taskData;

  const { t } = useTranslation(['adminControl']);
  const { fetchTasks, setTaskMessage } = useStore((state) => state);

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [taskName, setTaskName] = useState(name);

  const getSection = () => {
    if (loading) return <Skeleton characters={6} />;
    if (isEdit)
      return (
        <div className="edit-box-main">
          <input
            className={`${!taskName ? 'error' : ''}`}
            value={taskName}
            onChange={({ target: { value } }) => setTaskName(value)}
            type="text"
          />
          {!taskName && (
            <Typography variant={{ colour: 'danger' }}>
              {t('nameErrorLabel')}
            </Typography>
          )}
        </div>
      );
    if (isDelete)
      return (
        <Box variant={{ background: 'warning' }} space={4}>
          <Typography>{t('deleteConfirmationLabel')}</Typography>
          <Typography variant={{ bold: true }}>{taskName} ?</Typography>
        </Box>
      );
    return <Typography>{taskName}</Typography>;
  };

  const getAction = () => {
    if (loading) return <Skeleton characters={2} />;
    if (isEdit || isDelete)
      return (
        <div className="action-items-main">
          <div className="action-item">
            <IconButton
              icon={Checkmark}
              action="accept"
              variant={{ raised: true }}
              onPress={onConfirm}
            />
          </div>
          <div className="action-item">
            <IconButton
              icon={Close}
              action="cancel"
              variant={{ raised: true }}
              onPress={onCancel}
            />
          </div>
        </div>
      );

    return (
      <div className="action-items-main">
        <div className="action-item">
          <IconButton
            icon={Edit}
            action="edit"
            variant={{ raised: true }}
            onPress={() => setIsEdit(true)}
          />
        </div>
        {canDeleted && (
          <div className="action-item">
            <IconButton
              icon={Delete}
              action="delete"
              variant={{ raised: true }}
              disabled
              onPress={() => setIsDelete(true)}
            />
          </div>
        )}
      </div>
    );
  };

  const onConfirm = async () => {
    if (isEdit && !taskName) return;
    setLoading(true);
    setIsEdit(false);
    setIsDelete(false);

    try {
      let result;
      if (isEdit) {
        const newPayload = { ...taskData, name: taskName };
        result = await putRequest(`task/${id}`, newPayload);
      }
      if (isDelete) {
        result = await deleteRequest(`task/${id}`);
      }
      if (result)
        setTaskMessage({
          active: true,
          type: 'success',
          message: `Activity ${isEdit ? 'updated' : 'deleted'} successfully`,
        });
    } catch (error) {
      console.log('Error' + error);
    } finally {
      setLoading(false);
      fetchTasks();
    }
  };

  const onCancel = () => {
    if (isEdit) {
      setIsEdit(false);
      setTaskName(name);
    }
    if (isDelete) setIsDelete(false);
  };

  return (
    <Table.Row key={id}>
      {/* For small screens hide project id cell */}
      <Responsive min="md" max="xl">
        <Table.Cell type="rowHeading">#{id}</Table.Cell>
      </Responsive>
      <Table.Cell>{getSection()}</Table.Cell>
      <Table.Cell>{getAction()}</Table.Cell>
    </Table.Row>
  );
}
