import { addDays, eachDayOfInterval, format } from 'date-fns';

export const getInitialTimesheetRowData = (weekStartDate) => {
  const initialData = {
    projectId: 0,
    taskId: 0,
    weekDate: format(weekStartDate, 'yyyy-MM-dd'),
    data: [],
    isValid: false,
  };
  initialData.data = eachDayOfInterval({
    start: weekStartDate,
    end: addDays(weekStartDate, 6),
  }).map((date) => ({
    workDate: format(date, 'yyyy-MM-dd'),
    hoursWorked: 0,
  }));
  return initialData;
};

export const getTransformedPayloadData = (timesheetData) =>
  timesheetData.map((row) => {
    delete row.isValid;
    return row;
  });

export const getTransformedResponseTimesheetData = (timesheetData) =>
  timesheetData.map((row) => ({
    ...row,
    data: row.data.sort((a, b) => new Date(a.workDate) - new Date(b.workDate)),
    isValid: true,
  }));

export const getTransformedPreviousWeekResponse = (
  timesheetData,
  weekStartDate
) => {
  const datesOfTheWeek = eachDayOfInterval({
    start: weekStartDate,
    end: addDays(weekStartDate, 6),
  }).map((date) => format(date, 'yyyy-MM-dd'));

  return timesheetData.map(({ projectId, taskId, data }) => ({
    projectId,
    taskId,
    weekDate: format(weekStartDate, 'yyyy-MM-dd'),
    data: data.map(({ hoursWorked }, index) => ({
      workDate: datesOfTheWeek[index],
      hoursWorked,
    })),
    isValid: true,
  }));
};

export const validateTimeSheet = (timesheetData) =>
  timesheetData.length > 0 && timesheetData.every((rowData) => rowData.isValid);

export const savedDataInCurrentTimesheet = (timesheetData) =>
  timesheetData.every((rowData) => rowData.id);

export const getTimesheetRowIds = (timesheetData) =>
  timesheetData.reduce((acc, current) => {
    if (current.id) acc.push(current.id);
    return acc;
  }, []);

export const calculateTotalHoursByDayOfWeek = (timesheetData) => {
  const totalHoursArray = new Array(8).fill(0);
  timesheetData.forEach((item) => {
    item.data.forEach((dataItem, index) => {
      totalHoursArray[0] +=
        dataItem.hoursWorked == '' ? 0 : dataItem.hoursWorked;
      totalHoursArray[index + 1] +=
        dataItem.hoursWorked == '' ? 0 : dataItem.hoursWorked;
    });
  });
  return totalHoursArray;
};
