import { useState } from 'react';

import { endOfWeek, startOfWeek } from 'date-fns';
import { Button, Typography } from '@telus-uds/components-web';

import useStore from '../../../store/store';
import MultiSelectFilter from '../../../components/MultiSelectFilter';
import DateRangePicker from '../../../components/DateRangePicker';
import { FIRST_DAY_OF_WEEK } from '../../../utils/constants';

export default function Filters() {
  const {
    reportingFilters,
    filtersData,
    applyReportingFilters
  } = useStore((state) => state);
  const [projectSelected, setProjectSelected] = useState(reportingFilters.projects);
  const [employeeSelected, setEmployeeSelected] = useState(reportingFilters.employees);
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(reportingFilters.startDate),
    endDate: new Date(reportingFilters.endDate),
    key: 'selection'
  }]);

  const onResetFilter = async () => {
    // Initial filter values
    const startDate = startOfWeek(new Date(), {
      weekStartsOn: FIRST_DAY_OF_WEEK,
    });
    const endDate = endOfWeek(new Date(), {
      weekStartsOn: FIRST_DAY_OF_WEEK,
    });
    const employees = [];
    const projects = [];

    // Reset filter values
    setEmployeeSelected(employees);
    setProjectSelected(projects);
    setDateRange([{
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      key: 'selection'
    }]);

    // Get data for updated filters
    await applyReportingFilters({
      startDate,
      endDate,
      employees,
      projects,
    });
  };

  return (
    <div className="filters">
      <h2>Filters</h2>
      <div className="filter-section">
        <MultiSelectFilter
          label="Employee Filter"
          items={filtersData?.employeeList || []}
          selectedItems={employeeSelected}
          setSelectedItems={setEmployeeSelected}
        />
      </div>
      <div className="filter-section">
        <MultiSelectFilter
          label="Project Filter"
          items={filtersData?.projectList || []}
          selectedItems={projectSelected}
          setSelectedItems={setProjectSelected}
        />
      </div>
      <div className="filter-section">
        <Typography block variant={{ size: 'h4', colour: 'light' }}>
          Date Filter
        </Typography>
        <DateRangePicker
          label="Dates"
          range={dateRange}
          setRange={setDateRange}
        />
      </div>
      <div className="filter-section">
        <div className="filter-action">
          <Button
            variant={{ priority: 'high' }}
            onPress={() =>
              applyReportingFilters({
                ...reportingFilters,
                startDate: dateRange[0].startDate,
                endDate: dateRange[0].endDate,
                employees: employeeSelected,
                projects: projectSelected,
              })
            }
          >
            Apply
          </Button>
          <Button onPress={onResetFilter}>Reset All</Button>
        </div>
      </div>
    </div>
  );
}
