import {
  Box,
  Typography,
  Spacer,
  Spinner,
  Responsive,
} from '@telus-uds/components-web';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import useStore from '../../../../../store/store';

import DownloadCsv from './DownloadCsv';

export default function Chart({ options, heading, downloadCsvFor }) {
  const { showLoadingReportingData } = useStore((state) => state);

  return (
    <Box
      variant={{ background: 'light' }}
      space={{ xs: 2, sm: 2, md: 4, lg: 4, xl: 4 }}
    >
      <Spinner
        label="Fetching records..."
        inline
        show={showLoadingReportingData}
      >
        <div className="chart-top-bar">
          <Responsive min="xs" max="md">
            <Typography block variant={{ size: 'medium', bold: true }}>
              {heading}
            </Typography>
          </Responsive>
          <Responsive min="lg" max="xl">
            <Typography block variant={{ size: 'large', bold: true }}>
              {heading}
            </Typography>
          </Responsive>

          <DownloadCsv downloadCsvFor={downloadCsvFor} />
        </div>
        <Spacer space={4} />
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Spinner>
    </Box>
  );
}
