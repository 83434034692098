import { useEffect, useState } from 'react';
import { Spacer, Tabs } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '../../components/Breadcrumbs';
import { BREADCRUMBS_LIST } from '../../utils/constants';

import Projects from './components/Projects';
import Tasks from './components/Tasks';

import useStore from '../../store/store';

export default function AdminControl() {
  const { setCurrentPageNumberProject, setCurrentPageNumberTask } = useStore(
    (state) => state
  );
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('PROJECT');

  useEffect(() => {
    /**
     * On component un mount return the current page to 1
     */
    return () => {
      setCurrentPageNumberProject(1);
      setCurrentPageNumberTask(1);
    };
  }, []);

  return (
    <>
      <Breadcrumbs breadcrumbsItems={BREADCRUMBS_LIST.adminControl} />
      <Spacer space={6} />
      <Tabs
        items={[
          { label: t('adminControl:labelProject'), id: 'PROJECT' },
          { label: t('adminControl:labelTask'), id: 'TASK' },
        ]}
        value={selectedTab}
        onChange={(value) => setSelectedTab(value)}
      />
      {selectedTab === 'PROJECT' && <Projects />}
      {selectedTab === 'TASK' && <Tasks />}
    </>
  );
}
