import { addDays, format } from 'date-fns';
import { Table } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../../store/store';

const DaysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const cellHeaderToken = {
  fontSize: '14px',
};

export default function TableHeader() {
  const { t } = useTranslation(['timeEntry', 'common']);
  const { weekStartDate } = useStore((state) => state);
  if (!weekStartDate) return null;

  return (
    <Table.Header>
      <Table.Cell align="center" tokens={cellHeaderToken}>
        {t('timeEntry:labelProjectName')}
      </Table.Cell>
      <Table.Cell align="center" tokens={cellHeaderToken}>
        {t('timeEntry:labelActivityClassification')}
      </Table.Cell>
      <Table.Cell align="center" tokens={cellHeaderToken}>
        {t('common:labelTotal')}
      </Table.Cell>
      {weekStartDate &&
        DaysOfWeek.map((day, index) => {
          const isCurrentDate =
            format(addDays(weekStartDate, index), 'yyyyMMdd') ===
            format(new Date(), 'yyyyMMdd');
          return (
            <Table.Cell
              key={`HEADER-WEEK-DATE-${index}`}
              tokens={{
                ...cellHeaderToken,
                ...(isCurrentDate
                  ? { cellBackground: 'rgba(75, 40, 109, 0.4)' }
                  : {}),
              }}
            >
              <div className="time-entry-table__date-column-header">
                <div>{format(addDays(weekStartDate, index), 'E')}</div>
                <div>{format(addDays(weekStartDate, index), 'MMM d')}</div>
              </div>
            </Table.Cell>
          );
        })}
      <Table.Cell tokens={cellHeaderToken}>Action</Table.Cell>
    </Table.Header>
  );
}
