import { startOfWeek, endOfWeek, format } from 'date-fns';

import { postRequest } from '../../config/apiClient';
import { FIRST_DAY_OF_WEEK } from '../../utils/constants';
import { removeZeroHoursWorked, getFiltersData } from '../../utils/utils';

const dashboardAndReportingSlice = (set, get) => ({
  showLoadingReportingData: false,
  setShowLoadingReportingData: (value) =>
    set(() => ({ showLoadingReportingData: value })),
  filtersData: null,
  setFiltersData: (value) =>
    set(() => ({ filtersData: { ...value } })),
  reportingFilters: {
    startDate: startOfWeek(new Date(), { weekStartsOn: FIRST_DAY_OF_WEEK }),
    endDate: endOfWeek(new Date(), { weekStartsOn: FIRST_DAY_OF_WEEK }),
    projects: [],
    employees: [],
  },
  setReportingFilters: (value) =>
    set(() => ({ reportingFilters: { ...value } })),
  applyReportingFilters: async (filters) => {
    get().setShowLoadingReportingData(true);
    await get().setReportingFilters(filters);
    await get().fetchReportingData();
  },
  reportingData: [],
  reportingDataByDates: [],
  fetchReportingData: async () => {
    const { startDate, endDate } = get().reportingFilters;
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');
    get().setShowLoadingReportingData(true);
    try {
      // Get reporting data
      const employeeId = get().reportingFilters.employees;
      const projectId = get().reportingFilters.projects;
      const {
        data: { data: { results: reportingDataResults } }
      } = await postRequest('reports/reportByTaskProjectTeam', {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        employeeId,
        projectId
      });
      const reportingData = removeZeroHoursWorked(reportingDataResults);

      // Get filters data if it is not loaded
      let filtersData = get().filtersData;
      // if (!filtersData) {
        let nonFilteredReportResults = reportingData;
        if (employeeId.length > 0 || projectId.length > 0) {
          const {
            data: { data: { results } }
          } = await postRequest('reports/reportByTaskProjectTeam', {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            employeeId: [],
            projectId: [],
          });
          nonFilteredReportResults = results;
        }
        // Create filters lists
        const employeeList = getFiltersData(nonFilteredReportResults, 'employeeId', 'employeeName');
        const projectList = getFiltersData(nonFilteredReportResults, 'projectId', 'projectName');
        const taskList = getFiltersData(nonFilteredReportResults, 'taskId', 'taskName');
        filtersData = { employeeList, projectList, taskList };
      // }

      set(() => ({
        reportingData,
        filtersData,
        showLoadingReportingData: false,
      }));
    } catch (e) {
      console.log(e);
    }
  },
});

export default dashboardAndReportingSlice;
